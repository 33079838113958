import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Divider,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from "@chakra-ui/react";
import { GetDocumentsFromCaseResponse } from "../api/type";
import { Patient, Person, PoliceCaseWithDetails } from "app/api/type";
import dayjs from "dayjs";
import { env } from "app/config";
import { Select } from "chakra-react-select";
import {
  useCreateInjuryCertificateMutation,
  useLazyGetCertificateFormFieldsQuery
} from "../api";

type Props = {
  data: GetDocumentsFromCaseResponse;
  person: Person;
  patient: Patient;
  policeCaseWithDetails: PoliceCaseWithDetails;
};

interface CertificateRecordType {
  label: string;
  title: string;
  value: string;
  documentId: string | null;
}

const InjuryCertificateModal = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reference, setReference] = useState<string>();
  const [issueDate, setIssueDate] = useState<string>();
  const [timeOfOccurrence, setTimeOfOccurrence] = useState<string>();
  const [colorOfWound, setColorOfWound] = useState<string>();
  const [loadingForCreateCertificate, setLoadingForCreateCertificate] =
    useState<boolean>(false);
  const [createInjuryCertificate, createInjuyCertificateResult] =
    useCreateInjuryCertificateMutation();
  const [headers, setHeaders] = useState<
    {
      label: string;
      title: string;
    }[]
  >();

  const [certificateRecords, setCertificateRecords] = useState<
    CertificateRecordType[][]
  >([[]]);

  const [getCertificateFormFieldQuery, getCertificateFormFieldResult] =
    useLazyGetCertificateFormFieldsQuery();

  useEffect(() => {
    getCertificateFormFieldQuery({
      certificateTypeName: "Injury Certificate"
    });
  }, []);

  useEffect(() => {
    if (props.data.documentsWithAttributes) {
      const documents = props.data.documentsWithAttributes;
      const newCertificateRecords: CertificateRecordType[][] = [];
      for (const document of documents) {
        let record: CertificateRecordType[] = [];
        for (const attribute of document.documentAttributes) {
          record.push({
            documentId: document.id,
            label: attribute.fieldLabelName,
            title: "",
            value: attribute.value
          });
        }
        newCertificateRecords.push(record);
      }
      setCertificateRecords(newCertificateRecords);
    }
  }, [props]);

  const onTabularDataChange = (
    index: number,
    label: string,
    value: string,
    documentId: string
  ) => {
    while (index >= certificateRecords.length) {
      certificateRecords.push([]); // Add empty arrays for any missing indices
    }
    // Create a copy of certificateRecords to avoid mutating state directly
    const updatedCertificateRecords = [...certificateRecords];

    // Check if the record exists at the specified index and label
    const recordIndex = updatedCertificateRecords[index].findIndex(
      (record) => record.label === label
    );

    if (recordIndex !== -1) {
      // If the record exists, update its value
      updatedCertificateRecords[index][recordIndex] = {
        ...updatedCertificateRecords[index][recordIndex],
        value: value
      };
      // console.log("Updating value of label", label, "=", value);
    } else {
      // If the record does not exist, add a new record with the label and value
      updatedCertificateRecords[index].push({
        documentId: documentId, // Assuming you want to keep track of the documentId
        label: label,
        title: "", // Assuming the title is the same as the label
        value: value
      });
      // console.log("Adding new record with label", label, "and value", value);
    }

    setCertificateRecords(updatedCertificateRecords);
  };

  const getTabularDataValue = (index: number, label: string) => {
    if (certificateRecords[index]) {
      const result = certificateRecords[index].find((record) => {
        return record.label === label;
      });
      // console.log("Getting value of label", label, "=", result?.value);

      return result ? result.value : "-";
    } else {
      return "-";
    }
  };

  useEffect(() => {
    if (
      getCertificateFormFieldResult &&
      getCertificateFormFieldResult.isSuccess
    ) {
      console.log(getCertificateFormFieldResult.data);
      setHeaders(
        getCertificateFormFieldResult.data.certificateFields
          .map((certificateFormField) => {
            return {
              label: certificateFormField.label,
              title: certificateFormField.title,
              isTabular: certificateFormField.isTabularData
            };
          })
          .filter((field) => field.isTabular === true)
      );
    }
  }, [getCertificateFormFieldResult]);

  const handleOnCreateCertificate = () => {
    const allCertificateRecord: CertificateRecordType[] = [];

    for (const row of certificateRecords) {
      for (const record of row) {
        allCertificateRecord.push(record);
      }
    }
    if (reference) {
      allCertificateRecord.push({
        label: "Reference No",
        title: "Reference No",
        value: reference,
        documentId: null
      });
    }
    if (issueDate) {
      allCertificateRecord.push({
        label: "Issue Date",
        title: "Issue Date",
        value: issueDate,
        documentId: null
      });
    }

    if (timeOfOccurrence) {
      allCertificateRecord.push({
        label: "Time of Occurrence",
        title: "Time of Occurrence",
        value: timeOfOccurrence,
        documentId: null
      });
    }

    if (colorOfWound) {
      allCertificateRecord.push({
        label: "Color of the wound",
        title: "Color of the wound",
        value: colorOfWound,
        documentId: null
      });
    }

    const data = {
      certificateTypeName: "Injury Certificate",
      policeCaseId: props.policeCaseWithDetails.policeCase.id,
      patientId: props.patient.id,
      certificateRecords: allCertificateRecord.map((record) => {
        return {
          documentId: record.documentId,
          certificateFormFieldLabel: record.label,
          value: record.value
        };
      })
    };
    if (data.certificateRecords.length > 0) {
      setLoadingForCreateCertificate(true);
      createInjuryCertificate({ data: data });
    }
  };

  useEffect(() => {
    if (
      createInjuyCertificateResult &&
      createInjuyCertificateResult.isSuccess
    ) {
      setLoadingForCreateCertificate(false);
      onClose();
    }
  }, [createInjuyCertificateResult, loadingForCreateCertificate]);

  const addNatureOfInjury = (
    index: number,
    documentId: string,
    value: string
  ) => {
    const updatedCertificateRecords = certificateRecords.map((doc, idx) =>
      idx === index ? [...doc] : doc
    );

    const newNatureOfInjuryRecord = {
      documentId: documentId,
      label: "Nature of Injury",
      value: value,
      title: "Nature of Injury"
    };

    // Check if the document exists at the specified index
    if (updatedCertificateRecords[index]) {
      // Add the new record to the specified document's record array
      updatedCertificateRecords[index].push(newNatureOfInjuryRecord);
    } else {
      // If the document does not exist at the index, create it and add the new record
      updatedCertificateRecords[index] = [newNatureOfInjuryRecord];
    }

    // Update the state with the modified records
    setCertificateRecords(updatedCertificateRecords);
  };

  return (
    <>
      <Button onClick={onOpen} size={"2xl"} padding={2}>
        Generate Injury Certificate
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>
            <Text fontSize={"3xl"}>Injury Certificate</Text>
            <Text fontSize={"xl"}>Department of Casualty</Text>
            <Text>{env.hospitalName}</Text>
            <Text>{env.hospitalAddress}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* First Row: Reference and Date */}
              <Stack direction={"row"} gap={4} justifyContent={"space-between"}>
                <FormControl flexDirection={"row"} width={"25%"}>
                  <FormLabel>Reference</FormLabel>
                  <Input
                    onChange={(e) => {
                      setReference(e.target.value);
                    }}
                    value={reference}
                  />
                </FormControl>
                <FormControl flexDirection={"row"} width={"25%"}>
                  <FormLabel>Date</FormLabel>
                  <Input
                    type={"date"}
                    onChange={(e) => {
                      setIssueDate(e.target.value);
                    }}
                    value={issueDate}
                  />
                </FormControl>
              </Stack>

              {/* Second Row: Patient Information */}
              <Stack direction={"row"} gap={4} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Patient Name: </Text>
                  <Text>{props.person.name}</Text>
                </Stack>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Date of Birth: </Text>
                  <Text>
                    {props.person.dateOfBirth
                      ? dayjs(props.person.dateOfBirth).format("DD/MM/YYYY")
                      : ""}
                  </Text>
                </Stack>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Sex: </Text>
                  <Text>{props.person.gender}</Text>
                </Stack>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Date: </Text>
                  <Text>
                    {props.person.createdAt
                      ? dayjs(props.person.createdAt).format("DD/MM/YYYY")
                      : ""}
                  </Text>
                </Stack>
              </Stack>

              {/* Third Row: Additional Patient Information */}
              <Stack direction={"row"} gap={4} justifyContent={"space-between"}>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Reg No: </Text>
                  <Text>{props.patient.id}</Text>
                </Stack>
                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Father's or Guardian's Name: </Text>
                  <Text>{props.person.fatherName}</Text>
                </Stack>

                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Spouse's Name: </Text>
                  <Text>{props.person.spouseName}</Text>
                </Stack>

                <Stack direction={"row"}>
                  <Text fontWeight={"bold"}>Contact Number: </Text>
                  <Text>{props.person.contactNumber}</Text>
                </Stack>
              </Stack>
            </Stack>

            <Text
              fontWeight={"bold"}
              borderBottom={"1px solid #000"}
              marginTop={4}
              width={"fit-content"}
            >
              Injury Notes
            </Text>
            {headers && (
              <TableContainer>
                <Table
                  variant="simple"
                  width="100%"
                  borderWidth="2px"
                  borderRadius="md"
                >
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      {headers.map((header, outerIndex) => (
                        <Th
                          key={outerIndex}
                          whiteSpace="normal"
                          style={{ maxWidth: "8%" }}
                        >
                          {header.title}
                        </Th>
                      ))}
                      <Th>Nature of Injury (Simple/Grievous)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {props.data.documentsWithAttributes.map(
                      (documentWithAttributes, index) => (
                        <Tr>
                          <Td>{index + 1}</Td>
                          {headers.map((header, innerIndex) => {
                            return (
                              <Td>
                                <Input
                                  onChange={(e) =>
                                    onTabularDataChange(
                                      index,
                                      header.label,
                                      e.target.value,
                                      documentWithAttributes.id
                                    )
                                  }
                                  value={getTabularDataValue(
                                    index,
                                    header.label
                                  )}
                                />
                              </Td>
                            );
                          })}
                          <Td>
                            <Select
                              options={[
                                {
                                  label: "Simple",
                                  value: "Simple"
                                },
                                {
                                  label: "Grievous",
                                  value: "Grievous"
                                }
                              ]}
                              onChange={(selectedOption) => {
                                if (selectedOption?.value)
                                  addNatureOfInjury(
                                    index,
                                    documentWithAttributes.id,
                                    selectedOption?.value
                                  );
                              }}
                            />
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <Divider marginTop={4} />
            <Stack direction={"row"} gap={4} justifyContent={"space-between"}>
              <FormControl flexDirection={"row"} width={"25%"}>
                <FormLabel>Time of Occurrence</FormLabel>
                <Input
                  type={"date"}
                  onChange={(e) => {
                    setTimeOfOccurrence(e.target.value);
                  }}
                  value={timeOfOccurrence}
                />
              </FormControl>

              <FormControl flexDirection={"row"} width={"25%"}>
                <FormLabel>Color of the wound</FormLabel>
                <Input
                  onChange={(e) => {
                    setColorOfWound(e.target.value);
                  }}
                  value={colorOfWound}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            {/* Add any additional buttons or actions here */}
            <Button
              colorScheme="blue"
              mr={3}
              isLoading={loadingForCreateCertificate}
              onClick={() => {
                handleOnCreateCertificate();
              }}
            >
              Generate Certificate
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default InjuryCertificateModal;
