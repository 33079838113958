import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnDef,
  PaginationState
} from "@tanstack/react-table";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  ButtonGroup,
  Center,
  IconButton,
  Text,
  VStack,
  HStack
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";

import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight
} from "react-icons/fa";

interface TableGeneratorProps {
  data: any[];
  headers: string[];
  pageSize: number;
  pageIndex: number;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  pageCount: number;
}

export const TableGenerator: React.FC<TableGeneratorProps> = (
  props: TableGeneratorProps
) => {
  const columnHelper = createColumnHelper<any>();

  const columns = useMemo<ColumnDef<any, any>[]>(
    () =>
      props.headers.map((headerKey) => {
        return columnHelper.accessor(headerKey, {
          id: headerKey,
          header: headerKey,
          cell: (info) => info.row.original[headerKey]
        });
      }),
    []
  );

  const data = props.data;
  const pagination = React.useMemo(
    () => ({
      pageIndex: props.pageIndex,
      pageSize: props.pageSize
    }),
    [props.pageIndex, props.pageSize]
  );

  const table = useReactTable<any>({
    data,
    columns,
    pageCount: props.pageCount + 1,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination
    },
    onPaginationChange: props.setPagination,
    manualPagination: true
  });

  if (props.data.length === 0) {
    return (
      <Center
        width="100%"
        height="100px"
        borderRadius={"md"}
        borderWidth="1px"
        borderColor="gray.200"
        borderStyle={"dashed"}
      >
        <Text
          color="gray.400"
          fontSize={"sm"}
          fontWeight={"bold"}
          textTransform={"uppercase"}
        >
          No data found
        </Text>
      </Center>
    );
  }
  return (
    <>
      <TableContainer>
        <Table variant="simple" size="sm">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td fontSize={{ base: "xs", md: "sm" }} key={cell.id}>
                    {cell.getIsPlaceholder()
                      ? null
                      : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Center>
        <VStack>
          <HStack pt={{ base: "2", md: "5", lg: "8" }} w={"400"}>
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
              {`Page ${props.pageIndex + 1} of ${props.pageCount || 1}`}
            </Text>
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>|</Text>
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>Showing</Text>
            <Select
              size={"sm"}
              defaultValue={{
                label: String(props.pageSize),
                value: props.pageSize
              }}
              styles={{
                option: (styles, state) => ({
                  ...styles,
                  width: "200px",
                  minWidth: "130px"
                })
              }}
              onChange={(e) => {
                table.setPageSize(e?.value || 10);
              }}
              options={[
                { label: "10", value: 10 },
                { label: "20", value: 20 },
                { label: "30", value: 30 }
              ]}
            />
            <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>Results</Text>
          </HStack>
          <ButtonGroup variant={"outline"} colorScheme="blue" isAttached pt={2}>
            <IconButton
              onClick={() => table.setPageIndex(0)}
              isDisabled={props.pageIndex <= 0}
              aria-label="First Page"
              icon={<FaAngleDoubleLeft />}
            />
            <IconButton
              onClick={() => table.previousPage()}
              isDisabled={props.pageIndex <= 0}
              aria-label="Previous Page"
              icon={<FaAngleLeft />}
            />
            <IconButton
              onClick={() => table.nextPage()}
              isDisabled={props.pageIndex >= props.pageCount - 1}
              aria-label="Next Page"
              icon={<FaAngleRight />}
            />
            <IconButton
              onClick={() => table.setPageIndex(props.pageCount - 1)}
              isDisabled={props.pageIndex >= props.pageCount - 1}
              aria-label="Last Page"
              icon={<FaAngleDoubleRight />}
            />
          </ButtonGroup>
        </VStack>
      </Center>
    </>
  );
};
