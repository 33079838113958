import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { env } from "app/config";
import dayjs from "dayjs";
import { DocumentWithAttributes } from "app/api/type";

interface PrintableDischargeCertificatePageProps {
  data: DocumentWithAttributes;
  documentAttribute: {
    [key: string]: { level: string; value: string };
  };
}

Font.register({
  family: "Noto Serif Bengali",
  fonts: [
    { src: "/fonts/NotoSerifBengali-Regular.ttf" },
    { src: "/fonts/NotoSerifBengali-Bold.ttf", fontWeight: 600 }
  ]
});

Font.register({
  family: "Kalpurush",
  src: "/fonts/Kalpurush.ttf"
});

Font.register({
  family: "Lora",
  fonts: [
    { src: "/fonts/Lora-Regular.ttf" },
    { src: "/fonts/Lora-Bold.ttf", fontWeight: 600 }
  ]
});

const styles = StyleSheet.create({
  body: {
    width: "100%",
    padding: 15,
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "50px",
    objectFit: "contain",
    textAlign: "center"
  },
  barcode: {
    width: "90px",
    objectFit: "contain",
    alignSelf: "center",
    bottom: "2px"
  },

  title: {
    alignSelf: "center",
    width: "60%",
    fontSize: 11,
    marginTop: 5,
    marginBottom: 10,
    textAlign: "center",
    backgroundColor: "#808080"
  },
  subTitle: {
    fontSize: 12,
    marginTop: 50,
    paddingTop: 5,
    paddingBottom: 5,
    textAlign: "center"
  },
  header: {
    fontSize: 11,
    top: 20,
    left: 50,
    right: 50,
    display: "flex",
    flexDirection: "row"
  },
  headerLeft: {
    width: "50%",
    left: 0,
    paddingTop: 5,
    paddingBottom: 5
  },
  headerRight: {
    right: 0,
    width: "50%",
    paddingTop: 5,
    paddingBottom: 5
  },
  rightSide: {
    marginLeft: "auto"
  },
  details: {
    fontSize: 8
  },
  table: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #dfdfdf"
  },
  tableHeader: {
    backgroundColor: "#dfdfdf",
    border: "0.5px solid #dfdfdf",
    fontWeight: "bold"
  },
  tableFooter: {
    borderBottom: "1px solid #dfdfdf",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    fontSize: 8
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8,
    border: "0.5px solid #dfdfdf"
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: 8,
    border: "0.5px solid #efefef"
  },
  serial: {
    width: "5%",
    textAlign: "left",
    padding: "1px",
    paddingLeft: "5px"
  },
  itemName: {
    width: "50%",
    textAlign: "center",
    padding: "1px"
  },
  price: {
    width: "15%",
    textAlign: "right",
    padding: "1px"
  },
  quantity: {
    width: "15%",
    textAlign: "center",
    padding: "1px"
  },
  totalPrice: {
    width: "15%",
    textAlign: "right",
    padding: "1px",
    paddingRight: "5px"
  },
  borderedBox: {
    marginTop: 10,
    paddingHorizontal: 5,
    paddingTop: 2,
    paddingBottom: 20,
    borderWidth: 1,
    borderColor: "#000"
  },
  borderedContent: {
    marginTop: 5,
    fontSize: 8
  },
  signature: {
    left: "265",
    width: "100px",
    borderTop: "1px solid #000",
    paddingTop: "2px",
    marginTop: "40px"
  },
  valueUnderlined: {
    paddingTop: "1px",
    borderBottom: "1px dotted #000",
    backgroundColor: "#FFFFFF"
  }
});

export const PrintableDischargeCertificatePage: React.FC<
  PrintableDischargeCertificatePageProps
> = (props: PrintableDischargeCertificatePageProps) => {
  return (
    <Document>
      <Page size="A5" style={styles.body}>
        <View
          style={{
            padding: "10px",
            border: "1px solid #000",
            borderRadius: 5,
            height: "98%"
          }}
        >
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    alignItems: "center"
                  }}
                  fixed
                >
                  <Image style={styles.logo} src="/images/bdgovtlogo.png" />
                  <View style={{ width: "70%" }}>
                    <View>
                      <Text
                        style={{
                          fontSize: 13,
                          alignSelf: "center",
                          fontFamily: "Kalpurush"
                        }}
                      >
                        {env.hospitalName}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: 8,
                          alignSelf: "center",
                          fontWeight: 600
                        }}
                      >
                        {env.hospitalAddress}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          fontSize: 9,
                          alignSelf: "center"
                        }}
                      >
                        {env.hospitalContact}
                      </Text>
                    </View>
                  </View>
                  <Image style={styles.logo} src={env.hospitalLogo} />
                </View>

                <Text style={[styles.title]}>DISCHARGE CERTIFICATE</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              marginTop: "10px"
            }}
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Patient's Name:
                </Text>
                <View style={[styles.valueUnderlined, { width: "180px" }]}>
                  <Text>{props.documentAttribute["Patient'sName"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Date of Birth:
                </Text>
                <View style={[styles.valueUnderlined, { width: "60px" }]}>
                  <Text>
                    {dayjs(props.documentAttribute["Age"].value).format(
                      "DD MMM YYYY"
                    )}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Gender:
                </Text>
                <View style={[styles.valueUnderlined, { width: "60px" }]}>
                  <Text>{props.documentAttribute["Gender"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Hospital Registration No:
                </Text>
                <View style={[styles.valueUnderlined, { width: "175px" }]}>
                  <Text>
                    {props.documentAttribute["HospitalRegistrationNo"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Religion:
                </Text>
                <View style={[styles.valueUnderlined, { width: "60px" }]}>
                  <Text>{props.documentAttribute["Religion"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Patient National ID:
                </Text>
                <View style={[styles.valueUnderlined, { width: "180px" }]}>
                  <Text>
                    {props.documentAttribute["PatientNationalID"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Father's/Husband's Name:
                </Text>
                <View style={[styles.valueUnderlined, { width: "270px" }]}>
                  <Text>
                    {props.documentAttribute["Father's/HusbandName"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Spouse's Name:
                </Text>
                <View style={[styles.valueUnderlined, { width: "305px" }]}>
                  <Text>{props.documentAttribute["Spouse'sName"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Contact Person:
                </Text>
                <View style={[styles.valueUnderlined, { width: "165px" }]}>
                  <Text> </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Phone:
                </Text>
                <View style={[styles.valueUnderlined, { width: "95px" }]}>
                  <Text>{props.documentAttribute["Phone"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Address:
                </Text>
                <View style={[styles.valueUnderlined, { width: "340px" }]}>
                  <Text>{props.documentAttribute["Address"].value} </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <View style={[styles.valueUnderlined, { width: "370px" }]}>
                  <Text> </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "25"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Patient Category:
                </Text>
                <View style={[styles.valueUnderlined, { width: "50px" }]}>
                  <Text>
                    {props.documentAttribute["PatientCategory"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Admission Department:
                </Text>
                <View style={[styles.valueUnderlined, { width: "150px" }]}>
                  <Text>
                    {props.documentAttribute["AdmissionDepartment"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Unit:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>{props.documentAttribute["Unit"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Ward/Cabin:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>{props.documentAttribute["Ward/Cabin"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Bed No:
                </Text>
                <View style={[styles.valueUnderlined, { width: "60px" }]}>
                  <Text>{props.documentAttribute["BedNo"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Disease/Problem Description:
                </Text>
                <View style={[styles.valueUnderlined, { width: "260px" }]}>
                  <Text>
                    {
                      props.documentAttribute["Disease/ProblemDescription"]
                        .value
                    }
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Unit Chief Prof./Asso. Prof:
                </Text>
                <View style={[styles.valueUnderlined, { width: "260px" }]}>
                  <Text>
                    {props.documentAttribute["UnitChiefProf./Asso.Prof"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center",
                marginTop: "2px"
              }}
            >
              <Text style={{ marginRight: 2 }} wrap>
                Diagnosis with ICD Code:
              </Text>
              <View style={[styles.valueUnderlined, { width: "270px" }]}>
                <Text>
                  {props.documentAttribute["DiagnosiswithICDCode"].value}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Date of Admission:
                </Text>
                <View style={[styles.valueUnderlined, { width: "170px" }]}>
                  <Text>
                    {dayjs(
                      props.documentAttribute["DateofAdmission"].value
                    ).format("DD MMMM YYYY")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Time:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>
                    {dayjs(
                      props.documentAttribute["DateofAdmission"].value
                    ).format("hh:mm A")}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Date of Discharge:
                </Text>
                <View style={[styles.valueUnderlined, { width: "170px" }]}>
                  <Text>
                    {dayjs(
                      props.documentAttribute["DateofDischarge"].value
                    ).format("DD MMMM YYYY")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Time:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>
                    {dayjs(
                      props.documentAttribute["DateofDischarge"].value
                    ).format("hh:mm A")}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "2px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Counter Signature:
                </Text>
                <View style={[styles.valueUnderlined, { width: "120px" }]}>
                  <Text>
                    {props.documentAttribute["CounterSignature"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Signature:
                </Text>
                <View style={[styles.valueUnderlined, { width: "120px" }]}>
                  <Text>{props.documentAttribute["Signature"].value}</Text>
                </View>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <View style={[styles.valueUnderlined, { width: "370px" }]}>
                <Text> </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <View style={[styles.valueUnderlined, { width: "370px" }]}>
                <Text> </Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center"
              }}
            >
              <View style={[styles.valueUnderlined, { width: "370px" }]}>
                <Text> </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: "15px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: 8
            }}
          >
            <View
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "5px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              ></View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Dr. 1 Name:
                </Text>
                <View style={[styles.valueUnderlined, { width: "135px" }]}>
                  <Text>{props.documentAttribute["Dr.1Name"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Designation:
                </Text>
                <View style={[styles.valueUnderlined, { width: "130px" }]}>
                  <Text>
                    {props.documentAttribute["Dr.1Designation"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  BMDC Reg. No:
                </Text>
                <View style={[styles.valueUnderlined, { width: "115px" }]}>
                  <Text>{props.documentAttribute["Dr.1BMDCReg.No"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Seal:
                </Text>
                <View style={{ width: "100px" }}>
                  <Text>{props.documentAttribute["Dr.1Seal"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "5px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Dr. 2 Name:
                </Text>
                <View style={[styles.valueUnderlined, { width: "135px" }]}>
                  <Text>{props.documentAttribute["Dr.2Name"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Designation:
                </Text>
                <View style={[styles.valueUnderlined, { width: "130px" }]}>
                  <Text>
                    {props.documentAttribute["Dr.2Designation"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  BMDC Reg. No:
                </Text>
                <View style={[styles.valueUnderlined, { width: "115px" }]}>
                  <Text>
                    {" "}
                    {props.documentAttribute["Dr.2BMDCReg.No"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "2px"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Seal:
                </Text>
                <View style={{ width: "100px" }}>
                  <Text>{props.documentAttribute["Dr.2Seal"].value}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            left: 20,
            right: 20,
            position: "absolute",
            bottom: "10px",
            paddingTop: "10px",
            justifyContent: "space-between"
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Powered By: BinduLogic Limited
            </Text>
            <Text
              style={{ fontSize: 8, color: "#666666" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Printed on: {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A5" style={styles.body}>
        <View
          style={{
            padding: "10px",
            border: "1px solid #000",
            borderRadius: 5,
            height: "98%"
          }}
        >
          <View
            style={{
              width: "100%",
              height: "180px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              border: "0.5px solid #000",
              borderRadius: 2,
              padding: 2
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Case Summary:</Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              marginTop: "20px"
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Investigations:</Text>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}CBC: Hb:
                </Text>
                <View style={[styles.valueUnderlined, { width: "110px" }]}>
                  <Text>{props.documentAttribute["CBC"].value}</Text>
                </View>
                <Text> gm/dl,</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  ESR:
                </Text>
                <View style={[styles.valueUnderlined, { width: "110px" }]}>
                  <Text>{props.documentAttribute["ESR"].value}</Text>
                </View>
                <Text> mm in 1st hr</Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginLeft: 8, marginRight: 2 }} wrap>
                  TC/WBC:
                </Text>
                <View style={[{ width: "50px" }]}>
                  <Text>{props.documentAttribute["TC/WBC"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  DC:N:
                </Text>
                <View style={[{ width: "15px" }]}>
                  <Text>{props.documentAttribute["DC"].value}</Text>
                </View>
                <Text>%,</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  L:
                </Text>
                <View style={[{ width: "15px" }]}>
                  <Text> </Text>
                </View>
                <Text>%,</Text>
              </View>{" "}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  E:
                </Text>
                <View style={[{ width: "15px" }]}>
                  <Text> </Text>
                </View>
                <Text>%,</Text>
              </View>{" "}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  M:
                </Text>
                <View style={[{ width: "15px" }]}>
                  <Text> </Text>
                </View>
                <Text>%,</Text>
              </View>{" "}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  B:
                </Text>
                <View style={[{ width: "15px" }]}>
                  <Text> </Text>
                </View>
                <Text>%</Text>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}CXR:
                </Text>
                <View style={[{ width: "350px" }]}>
                  <Text> </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}CT Scan of Chest:
                </Text>
                <View style={[{ width: "350px" }]}>
                  <Text> {props.documentAttribute["CTScanofChest"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}S. bilirubin:
                </Text>
                <View style={[styles.valueUnderlined, { width: "80px" }]}>
                  <Text>{props.documentAttribute["S.bilirubin"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  SGPT:
                </Text>
                <View style={[styles.valueUnderlined, { width: "80px" }]}>
                  <Text>{props.documentAttribute["SGPT"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  SGOT:
                </Text>
                <View style={[styles.valueUnderlined, { width: "80px" }]}>
                  <Text>{props.documentAttribute["SGOT"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Blood Sugar (F/R):
                </Text>
                <View style={[styles.valueUnderlined, { width: "110px" }]}>
                  <Text>
                    {props.documentAttribute["BloodSugar(F/R)"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  S. Creatinine:
                </Text>
                <View style={[styles.valueUnderlined, { width: "110px" }]}>
                  <Text>{props.documentAttribute["S.Creatinine"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}S. Electrolytes: Na+
                </Text>
                <View style={[styles.valueUnderlined, { width: "50px" }]}>
                  <Text>
                    {props.documentAttribute["S.Electrolytes:Na+"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  K+:
                </Text>
                <View style={[styles.valueUnderlined, { width: "50px" }]}>
                  <Text>{props.documentAttribute["K+"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Cl:
                </Text>
                <View style={[styles.valueUnderlined, { width: "50px" }]}>
                  <Text>{props.documentAttribute["Cl"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  HCO3:
                </Text>
                <View style={[styles.valueUnderlined, { width: "50px" }]}>
                  <Text>{props.documentAttribute["HC03"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Sptum for AFB Smear:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>
                    {props.documentAttribute["SptumforAFBSmear"].value}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  GeneXpert/MTB:
                </Text>
                <View style={[styles.valueUnderlined, { width: "100px" }]}>
                  <Text>{props.documentAttribute["GeneXpert/MTB"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Sptum for C/S:
                </Text>
                <View style={[styles.valueUnderlined, { width: "310px" }]}>
                  <Text>{props.documentAttribute["SptumforC/S"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}ECG:
                </Text>
                <View style={[styles.valueUnderlined, { width: "130px" }]}>
                  <Text>{props.documentAttribute["ECG"].value}</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  Echocardiogram:
                </Text>
                <View style={[styles.valueUnderlined, { width: "130px" }]}>
                  <Text>{props.documentAttribute["Echocardiogram"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}USG Chest/Abdomen:
                </Text>
                <View style={[styles.valueUnderlined, { width: "280px" }]}>
                  <Text>
                    {props.documentAttribute["USGChest/Abdomen"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Sprirometry:
                </Text>
                <View style={[styles.valueUnderlined, { width: "310px" }]}>
                  <Text>{props.documentAttribute["Sprirometry"].value}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Pleural fluid study:
                </Text>
                <View style={[styles.valueUnderlined, { width: "290px" }]}>
                  <Text>
                    {props.documentAttribute["Pleuralfluidstudy"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}FNAC (Lung lesion/ L.node):
                </Text>
                <View style={[styles.valueUnderlined, { width: "255px" }]}>
                  <Text>
                    {props.documentAttribute["FNAC(Lunglesion/L.node)"].value}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "4px"
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                  alignItems: "center"
                }}
              >
                <Text style={{ marginRight: 2 }} wrap>
                  •{"  "}Other:
                </Text>
                <View style={[styles.valueUnderlined, { width: "340px" }]}>
                  <Text>{props.documentAttribute["Other"].value}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            left: 20,
            right: 20,
            position: "absolute",
            bottom: "10px",
            paddingTop: "10px",
            justifyContent: "space-between"
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Powered By: BinduLogic Limited
            </Text>
            <Text
              style={{ fontSize: 8, color: "#666666" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Printed on: {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A5" style={styles.body}>
        <View
          style={{
            padding: "10px",
            border: "1px solid #000",
            borderRadius: 5,
            height: "98%"
          }}
        >
          <View
            style={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              border: "0.5px solid #000",
              borderRadius: 2,
              padding: 2
            }}
          >
            <Text style={{ fontWeight: "bold" }}>
              Treatment during Hospital stay:
            </Text>
            <Text> </Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              marginTop: "50px",
              gap: "5px"
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Outcome:</Text>
            <Text>•{"  "} Discharged with treatment and Advice.</Text>
            <Text>
              •{"  "} Discharge with referral to other institute/hospital.
            </Text>
            <Text>•{"  "} DOR/DAMA</Text>
          </View>
        </View>
        <View
          style={{
            left: 20,
            right: 20,
            position: "absolute",
            bottom: "10px",
            paddingTop: "10px",
            justifyContent: "space-between"
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Powered By: BinduLogic Limited
            </Text>
            <Text
              style={{ fontSize: 8, color: "#666666" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Printed on: {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>

      <Page size="A5" style={styles.body}>
        <View
          style={{
            padding: "10px",
            border: "1px solid #000",
            borderRadius: 5,
            height: "98%"
          }}
        >
          <View
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              border: "0.5px solid #000",
              borderRadius: 2,
              padding: 2
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Treatment on Discharge:</Text>
            <Text> </Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              fontSize: 8,
              marginTop: "70px",
              gap: "5px"
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Advice:</Text>
            <Text>•</Text>
            <Text>•</Text>
            <Text>•</Text>
            <Text>•</Text>
            <Text>•</Text>
          </View>
        </View>
        <View
          style={{
            left: 20,
            right: 20,
            position: "absolute",
            bottom: "10px",
            paddingTop: "10px",
            justifyContent: "space-between"
          }}
          fixed
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Powered By: BinduLogic Limited
            </Text>
            <Text
              style={{ fontSize: 8, color: "#666666" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
            <Text style={{ fontSize: 8, color: "#666666" }}>
              Printed on: {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
