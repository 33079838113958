import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useCreateCaseMutation } from "../api";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";

interface CreateCaseComponentProps { }

export const CreateCaseComponent: React.FC<CreateCaseComponentProps> = (
  props: CreateCaseComponentProps
) => {
  const [createCase, createCaseResult] = useCreateCaseMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [policeReference, setPoliceReference] = useState<string>();
  const [hospitalReference, setHospitalReference] = useState<string>();
  const [remarks, setRemarks] = useState<string>();

  const navigate = useNavigate();

  const handleCreateCase = () => {
    createCase({
      data: {
        policeCase: {
          policeReference: policeReference ? policeReference : null,
          hospitalReference: hospitalReference ? hospitalReference : null,
          remarks: remarks ? remarks : null
        }
      }
    });
  };

  useEffect(() => {
    if (createCaseResult.isSuccess) {
      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;
      navigate(
        `/${getDashboardName(locationCategory)}/case/${createCaseResult.data.policeCase.id
        }/details`
      );
    }
  }, [createCaseResult]);

  return (
    <>
      <Button
        size={{ base: "xs", lg: "sm" }}
        colorScheme="blue"
        onClick={onOpen}
        leftIcon={<FaPlus />}
      >
        Add New Police Case
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        size={{ base: "xs", md: "xl" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{ base: "md", lg: "lg" }} fontWeight="bold">
            Police Case Details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="3">
              {/* <FormControl>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Police Referred Document ID
                </FormLabel>
                <Input
                  size={{ base: "xs", lg: "sm" }}
                  value={policeReference}
                  onChange={(event) => {
                    setPoliceReference(event.target.value);
                  }}
                />
              </FormControl> */}
              <FormControl>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Hospital Reference
                </FormLabel>
                <Input
                  size={{ base: "xs", lg: "sm" }}
                  value={hospitalReference}
                  onChange={(event) => {
                    setHospitalReference(event.target.value);
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Remarks
                </FormLabel>
                <Textarea
                  size={{ base: "xs", lg: "sm" }}
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                />
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={{ base: "xs", lg: "sm" }}
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size={{ base: "xs", lg: "sm" }}
              colorScheme="blue"
              onClick={handleCreateCase}
              isLoading={createCaseResult.isLoading}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
