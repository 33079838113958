import React from "react";
import dayjs from "dayjs";
import {
  Link,
  PlacementWithLogical,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue
} from "@chakra-ui/react";
import {
  Log,
  Person,
  PoliceCase,
  User,
  Patient,
  RecordRequestStatusName
} from "app/api/type";
import { RecordRequestStatusTag } from "modules/record-request/components/record-request-status.tag";
import { useNavigate } from "react-router-dom";

interface Props {
  logWithDetails: {
    log: Log;
    userWithDetails: {
      user: Omit<User, "password">;
      person: Person;
    };
    patientWithDetails: {
      patient: Patient;
      person: Person;
    } | null;
    policeCase: PoliceCase | null;
  };
}

export const LogComponent: React.FC<Props> = (props: Props) => {
  const tooltipPosition: PlacementWithLogical | undefined = useBreakpointValue({
    base: "bottom",
    lg: "left"
  });

  const UserTooltip = () => {
    return (
      <Stack p="4">
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          User ID: {props.logWithDetails.userWithDetails.user.id}
        </Text>
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Email: {props.logWithDetails.userWithDetails.user.email}
        </Text>
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Occupation:{" "}
          {props.logWithDetails.userWithDetails.user.designationName}
        </Text>
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          BMDC/BNC Number:{" "}
          {props.logWithDetails.userWithDetails.user.medicalCertificationNumber
            ? props.logWithDetails.userWithDetails.user
                .medicalCertificationNumber
            : "Unknown"}
        </Text>
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Department:{" "}
          {props.logWithDetails.userWithDetails.user.departmentName
            ? props.logWithDetails.userWithDetails.user.departmentName
            : "Unknown"}
        </Text>
      </Stack>
    );
  };

  const PatientTooltip = () => {
    return (
      <Stack p="4">
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Name:{" "}
          {props.logWithDetails.patientWithDetails?.person.name
            ? props.logWithDetails.patientWithDetails?.person.name
            : "Unknown"}
        </Text>
        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Contact:{" "}
          {props.logWithDetails.patientWithDetails?.person.contactNumber
            ? props.logWithDetails.patientWithDetails?.person.contactNumber
            : "Unknown"}
        </Text>

        <Text fontSize={{ base: "xs", md: "sm", lg: "md" }}>
          Admitted On:{" "}
          {dayjs(
            props.logWithDetails.patientWithDetails?.patient.createdAt
          ).format("DD MMM YYYY")}
        </Text>
      </Stack>
    );
  };

  const navigate = useNavigate();

  return (
    <Stack
      p="4"
      borderRadius={"md"}
      bgColor="white"
      boxShadow={"md"}
      width="100%"
      borderStyle={"dashed"}
      borderColor={"gray.200"}
    >
      <Stack
        direction={{ base: "column", lg: "row", xl: "row" }}
        justify="space-between"
        alignItems={{
          base: "flex-start",
          sm: "flex-start",
          lg: "center"
        }}
      >
        <Stack w={{ base: "100%", lg: "auto" }}>
          <Text
            color={"gray.500"}
            fontSize={{ base: "xs", md: "sm", lg: "md" }}
            fontWeight="semibold"
          >
            {props.logWithDetails.log.policeCaseId
              ? `Case ${props.logWithDetails.log.policeCaseId}`
              : ``}

            {props.logWithDetails.log.policeCaseId &&
            props.logWithDetails.log.recordRequestId
              ? ` & `
              : ``}
            {props.logWithDetails.log.recordRequestId
              ? `Record Request ${props.logWithDetails.log.recordRequestId}`
              : ``}
          </Text>

          <Text fontSize={{ base: "xs", md: "sm", lg: "sm" }}>
            Logged at :{" "}
            {dayjs(props.logWithDetails.log.createdAt).format(
              "DD MMM YYYY hh:mm:ss A"
            )}
          </Text>
          <Text
            fontSize={{ base: "xs", md: "sm", lg: "sm" }}
            fontWeight="semibold"
            color="gray.700"
          >
            {props.logWithDetails.log.action.split(" ").map((word) => {
              if (word.startsWith("PT")) {
                return (
                  <Tooltip hasArrow label={<PatientTooltip />}>
                    <Text as="span" color="green.500">
                      {word}{" "}
                    </Text>
                  </Tooltip>
                );
              } else if (word.startsWith("{RS:") && word.endsWith("}")) {
                return (
                  <RecordRequestStatusTag
                    recordRequestStatusName={
                      word
                        .replace("{RS:", "")
                        .replace("}", "") as RecordRequestStatusName
                    }
                  />
                );
              } else if (word.startsWith("{RR:") && word.endsWith("}")) {
                return (
                  <Link
                    as="span"
                    color="green.500"
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${locationCategory}/record-request/activity-log/list`
                      );
                    }}
                  >
                    {word.replace("{RR:", "").replace("}", "")}
                  </Link>
                );
              } else if (word.startsWith("{C:") && word.endsWith("}")) {
                return (
                  <Link
                    as="span"
                    color="blue.500"
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${locationCategory}/case/default/activity-log/list`
                      );
                    }}
                  >
                    {word.replace("{C:", "").replace("}", "")}
                  </Link>
                );
              } else if (word.startsWith("{U:") && word.endsWith("}")) {
                return (
                  <Link
                    as="span"
                    color="orange.500"
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${locationCategory}/user/default/activity-log/list`
                      );
                    }}
                  >
                    {word.replace("{U:", "").replace("}", "")}
                  </Link>
                );
              } else {
                return <Text as="span">{word} </Text>;
              }
            })}
          </Text>
        </Stack>

        <Stack w={{ base: "100%", lg: "auto" }}>
          <Text fontSize={{ base: "xs", md: "sm", lg: "sm" }} color="gray.600">
            Logged By :{" "}
          </Text>
          <Tooltip hasArrow label={<UserTooltip />} placement={tooltipPosition}>
            <Text
              fontSize={{ base: "xs", md: "sm", lg: "sm" }}
              fontWeight="semibold"
              color="orange.600"
            >
              {props.logWithDetails.userWithDetails.person.name}
            </Text>
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};
