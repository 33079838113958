import React from "react";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CaseDetailsComponent } from "../components/case-details.component";

interface CaseDetailsPageProps {}

export const CaseDetailsPage: React.FC<CaseDetailsPageProps> = (
  props: CaseDetailsPageProps
) => {
  const { caseId } = useParams();

  let id = "default";
  if (caseId) {
    id = caseId;
  }

  return (
    <Stack>
      <CaseDetailsComponent policeCaseId={id} />
    </Stack>
  );
};
