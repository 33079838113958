import { Center, Image } from "@chakra-ui/react";
import { env } from "app/config";

export const DecorationImageComponent = () => {
  return (
    <Center>
      <Image
        src={env.hospitalLogo}
        alt="BinduHealth Police Case"
        width={{ base: "70px", md: "80px", lg: "100px" }}
      />
    </Center>
  );
};
