import { IDashboard } from "app/types";
import { LogoComponent } from "app/components/logo.component";
import { PlaceholderPage } from "modules/core/pages/placeholder.page";
import { FaBriefcase, FaHistory } from "react-icons/fa";
import { DashboardPage } from "modules/police-case/pages/dashboard.page";
import { SearchCasePage } from "modules/police-case/pages/search-case.page";
import { CaseDetailsPage } from "modules/police-case/pages/case-details.page";
import { UserLogPage } from "modules/log/pages/user-log.page";
import { CaseLogPage } from "modules/log/pages/case-log.page";
import { PatientDocumentPage } from "modules/police-case/pages/patient-document.page";

export const counterDashboard: IDashboard = {
  dashboardName: "counter",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/counter/dashboard",
      content: <DashboardPage />
    },
    {
      name: "Case List",
      link: "/counter/case/list",
      content: <PlaceholderPage />
    },
    {
      name: "Search Case",
      link: "/counter/case/search",
      content: <SearchCasePage />
    },
    {
      name: "Upload Case Documents",
      link: "/counter/document/upload",
      content: <PatientDocumentPage />
    },
    {
      name: "Case Details",
      link: "/counter/case/:caseId/details",
      content: <CaseDetailsPage />
    },
    {
      name: "User Logs",
      link: "/counter/user/:userId/activity-log/list",
      content: <UserLogPage />
    },
    {
      name: "Case Logs",
      link: "/counter/case/:caseId/activity-log/list",
      content: <CaseLogPage />
    }
  ],

  sidebar: [
    {
      name: "Dashboard",
      link: "/counter/dashboard",
      content: <DashboardPage />
    },
    {
      header: "Case Management",
      icon: FaBriefcase,
      pages: [
        // {
        //   name: "Case List",
        //   link: "/counter/case/list",
        //   content: <SearchCasePage />
        // },
        {
          name: "Search Case",
          link: "/counter/case/search",
          content: <SearchCasePage />
        }
        // {
        //   name: "Case Details",
        //   link: "/counter/case/default/details",
        //   content: <CaseDetailsPage />
        // }
      ]
    },

    {
      header: "Activity Logs",
      icon: FaHistory,
      pages: [
        {
          name: "My Logs",
          link: "/counter/user/default/activity-log/list",
          content: <UserLogPage />
        }
      ]
    }
  ]
};
