export const getDashboardName = (locationCategoryName: string) => {
  switch (locationCategoryName) {
    case "Super Admin":
      return "super-admin";
    case "Admin":
      return "admin";
    case "Medical Certification":
      return "medical-certification";
    case "Counter":
      return "counter";
    case "Ward":
      return "ward";
    default:
      return "medical-certification";
  }
};
