import { Button, Center, Spinner, Stack } from "@chakra-ui/react";
import { PaginationState } from "@tanstack/react-table";
import { RecordRequestStatusName } from "app/api/type";
import { TableGenerator } from "modules/core/components/table-generator.component";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useLazyGetRecordRequestsQuery } from "../api";
import { RecordRequestStatusTag } from "./record-request-status.tag";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";

interface Props {}

export const FulfilledRecordRequestTable: React.FC<Props> = (props: Props) => {
  const [getRequestRecords, getRequestRecordsResult] =
    useLazyGetRecordRequestsQuery();

  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });

  useEffect(() => {
    getRequestRecords({
      recordRequest: {
        recordRequestStatusName: "Fulfilled"
      },
      pagination: {
        pageIndex,
        pageSize
      }
    });
  }, [pageIndex, pageSize]);

  return (
    <Stack>
      {getRequestRecordsResult.data ? (
        <TableGenerator
          data={getRequestRecordsResult.data.recordRequests.map(
            (recordRequest, index) => {
              return {
                "Sl. No.": pageSize * pageIndex + index + 1,
                "Request Date": dayjs(recordRequest.requestedAt).format(
                  "DD MMM YYYY, hh:mm A"
                ),
                Deadline: dayjs(recordRequest.deadline).format(
                  "DD MMM YYYY, hh:mm A"
                ),
                "Request Source": recordRequest.requestSource,
                "Record Reference": recordRequest.requestDocumentText,
                Status: (
                  <RecordRequestStatusTag
                    recordRequestStatusName={
                      recordRequest.recordRequestStatusName as RecordRequestStatusName
                    }
                  />
                ),
                Action: (
                  <Button
                    size={{ base: "xs", lg: "sm" }}
                    colorScheme={"blue"}
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${getDashboardName(
                          locationCategory
                        )}/record-request/${recordRequest.id}/`
                      );
                    }}
                  >
                    Details
                  </Button>
                )
              };
            }
          )}
          headers={[
            "Sl. No.",
            "Request Date",
            "Deadline",
            "Request Source",
            "Record Reference",
            "Status",
            "Action"
          ]}
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPagination={setPagination}
          pageCount={Math.ceil(getRequestRecordsResult.data.count / pageSize)}
        />
      ) : (
        <Center width="100%" height="100px">
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};
