import React from "react";
import { FormattedCertificate } from "../api/type";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { InjuryCertificatePrintPage } from "./injury-certificate-print-page";

interface InjuryCertificatePrintComponentProps {
  data: FormattedCertificate;
}

export const InjuryCertificatePrintComponent: React.FC<
  InjuryCertificatePrintComponentProps
> = (props: InjuryCertificatePrintComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <InjuryCertificatePrintPage data={props.data} />;

  const requestReport = `Injury Certificate.pdf`;

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        // w={{ base: "100%", md: "50%" }}
        // size={{ base: "xs", md: "sm" }}
      >
        Print Injury Certificate {props.data.certificateId}
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={requestReport}
      />
    </>
  );
};
