import React from "react";
import { Divider, Stack, Text } from "@chakra-ui/react";
import { CreateRecordRequestModal } from "../components/create-record-request.modal";
import { PendingRecordRequestTable } from "../components/pending-record-request.table";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FulfilledRecordRequestTable } from "../components/fulfilled-record-request.table";
import { InitializedRecordRequestTable } from "../components/initialized-record-request.table";
import { ApprovedRecordRequestTable } from "../components/approved-record-request.table";
import { MissingRecordRequestTableComponent } from "../components/missing-record-request-table.component";
import { RejectRecordRequestTableComponent } from "../components/reject-record-request-table.component";

interface Props {}

export const RecordRequestPage: React.FC<Props> = (props: Props) => {
  const locationCategory = window.localStorage.getItem(
    "locationCategory"
  ) as string;

  return (
    <Stack>
      {locationCategory === "Medical Certification" && (
        <PageCardComponent>
          <Stack direction={"row"} justify={"space-between"}>
            <Text
              fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
              fontWeight={"bold"}
            >
              Initialized Requests
            </Text>{" "}
            <CreateRecordRequestModal />
          </Stack>
          <InitializedRecordRequestTable />
        </PageCardComponent>
      )}

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
            fontWeight={"bold"}
          >
            Pending Requests
          </Text>
        </Stack>
        <PendingRecordRequestTable />
      </PageCardComponent>
      <Divider />

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
            fontWeight={"bold"}
          >
            Approved Requests
          </Text>
        </Stack>
        <ApprovedRecordRequestTable />
      </PageCardComponent>

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
            fontWeight={"bold"}
          >
            Fulfilled Requests
          </Text>
        </Stack>
        <FulfilledRecordRequestTable />
      </PageCardComponent>

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
            fontWeight={"bold"}
          >
            Missing Requests
          </Text>
        </Stack>
        <MissingRecordRequestTableComponent />
      </PageCardComponent>

      <PageCardComponent>
        <Stack direction={"row"} justify={"space-between"}>
          <Text
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
            fontWeight={"bold"}
          >
            Rejected Requests
          </Text>
        </Stack>
        <RejectRecordRequestTableComponent />
      </PageCardComponent>
    </Stack>
  );
};
