import React from "react";
import { env } from "app/config";
import { Flex, Stack } from "@chakra-ui/react";
import { useDocumentTitle } from "hooks/use-document-title.hook";
import { SelectLocationComponent } from "../components/select-location.component";

interface Props {}

const SelectLocationPage: React.FC<Props> = (props: Props) => {
  useDocumentTitle("Location Change | " + env.appName);

  return (
    <Stack
      minH={"100vh"}
      bgColor="white"
      borderRadius="md"
      p={4}
      spacing={4}
      direction={{ base: "column", md: "row" }}
    >
      <Flex flex={1} p={8} align="center" justify="center">
        <SelectLocationComponent />
      </Flex>
    </Stack>
  );
};

export default SelectLocationPage;
