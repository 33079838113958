import React from "react";
import { Center, Image, Stack, Text, Link } from "@chakra-ui/react";

interface OrganizationComponentProps {}

export const OrganizationComponent: React.FC<OrganizationComponentProps> = (
  props: OrganizationComponentProps
) => {
  return (
    <Center width="100%" textAlign="center" pt="5">
      <Stack direction="column" width="100%">
        <Center>
          <Stack direction={{ base: "column" }} alignItems="center">
            <Text
              as="span"
              color="gray.500"
              fontSize={{ base: "xs", md: "sm", lg: "sm" }}
              fontWeight={"bold"}
            >
              © BinduHealth Police Case Module
            </Text>
            <Center>
              <Stack direction="row">
                <Text
                  color="gray.500"
                  fontSize={{ base: "xs", md: "sm", lg: "sm" }}
                >
                  a product of
                </Text>
                <Image
                  src="/images/bindulogic-logo.png"
                  width={5}
                  objectFit="cover"
                />
                <Link
                  color="blue.400"
                  href="https://bindulogic.com/"
                  isExternal
                  fontSize={{ base: "sm" }}
                >
                  <Text
                    as="span"
                    fontSize={{ base: "xs", md: "sm", lg: "sm" }}
                    fontWeight="bold"
                  >
                    Bindulogic Limited
                  </Text>
                </Link>
              </Stack>
            </Center>
          </Stack>
        </Center>
        <Text color="gray.500" fontSize="xs">
          Address: House 07, Road 02, Block B, Mirpur 10, Dhaka 1216 | Contact:
          01302606005
        </Text>
      </Stack>
    </Center>
  );
};
