export const genderList = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" }
];

export const patientTypeList = [
  { label: "OPD", value: "OPD" },
  { label: "IPD", value: "IPD" },
  { label: "Dead", value: "Dead" }
];

export const religions = [
  { label: "Buddhism", value: "Buddhism" },
  { label: "Christianity", value: "Christianity" },
  { label: "Hinduism", value: "Hinduism" },
  { label: "Islam", value: "Islam" },
  { label: "Other", value: "Other" },
  { label: "Unknown", value: "Unknown" }
];
