export const DischargeCertificateFieldInformation: {
  [key: string]: { fieldLabelName: string; fieldTypeName: string };
} = {
  name: { fieldLabelName: "Patient's Name", fieldTypeName: "Text" },
  age: { fieldLabelName: "Age", fieldTypeName: "Text" },
  gender: { fieldLabelName: "Gender", fieldTypeName: "Select" },
  hospitalRegistrationNo: {
    fieldLabelName: "Hospital Registration No",
    fieldTypeName: "Text"
  },
  fatherOrHusbandName: {
    fieldLabelName: "Father's/Husband Name",
    fieldTypeName: "Text"
  },
  spouseName: {
    fieldLabelName: "Spouse's Name",
    fieldTypeName: "Text"
  },
  phone: { fieldLabelName: "Phone", fieldTypeName: "Text" },
  religion: { fieldLabelName: "Religion", fieldTypeName: "Text" },
  patientNid: { fieldLabelName: "Patient National ID", fieldTypeName: "Text" },
  address: { fieldLabelName: "Address", fieldTypeName: "Text" },
  admissionDepartment: {
    fieldLabelName: "Admission Department",
    fieldTypeName: "Text"
  },
  patientCategory: {
    fieldLabelName: "Patient Category",
    fieldTypeName: "Text"
  },
  unit: {
    fieldLabelName: "Unit",
    fieldTypeName: "Text"
  },
  wardOrCabin: {
    fieldLabelName: "Ward/Cabin",
    fieldTypeName: "Text"
  },
  bedNo: {
    fieldLabelName: "Bed No",
    fieldTypeName: "Text"
  },
  unitProfessor: {
    fieldLabelName: "Unit Chief Prof./Asso. Prof",
    fieldTypeName: "Text"
  },
  dateOfAdmission: {
    fieldLabelName: "Date of Admission",
    fieldTypeName: "Text"
  },
  dateOfDischarge: {
    fieldLabelName: "Date of Discharge",
    fieldTypeName: "Text"
  },
  diagnosisIcdCode: {
    fieldLabelName: "Diagnosis with ICD Code",
    fieldTypeName: "Text"
  },
  counterSignature: {
    fieldLabelName: "Counter Signature",
    fieldTypeName: "Text"
  },
  signature: {
    fieldLabelName: "Signature",
    fieldTypeName: "Text"
  },
  diseaseDescription: {
    fieldLabelName: "Disease/Problem Description",
    fieldTypeName: "Text"
  },
  drOneName: {
    fieldLabelName: "Dr. 1 Name",
    fieldTypeName: "Text"
  },
  drTwoName: {
    fieldLabelName: "Dr. 2 Name",
    fieldTypeName: "Text"
  },
  drOneDesignation: {
    fieldLabelName: "Dr. 1 Designation",
    fieldTypeName: "Text"
  },
  drTwoDesignation: {
    fieldLabelName: "Dr. 2 Designation",
    fieldTypeName: "Text"
  },
  drOneRegNo: {
    fieldLabelName: "Dr. 1 BMDC Reg. No",
    fieldTypeName: "Text"
  },
  drTwoRegNo: {
    fieldLabelName: "Dr. 2 BMDC Reg. No",
    fieldTypeName: "Text"
  },
  drOneSeal: {
    fieldLabelName: "Dr. 1 Seal",
    fieldTypeName: "Text"
  },
  drTwoSeal: {
    fieldLabelName: "Dr. 2 Seal",
    fieldTypeName: "Text"
  },
  cbc: {
    fieldLabelName: "CBC",
    fieldTypeName: "Text"
  },
  esr: {
    fieldLabelName: "ESR",
    fieldTypeName: "Text"
  },
  tcOrWbc: {
    fieldLabelName: "TC/WBC",
    fieldTypeName: "Text"
  },
  dc: {
    fieldLabelName: "DC",
    fieldTypeName: "Text"
  },
  cxr: {
    fieldLabelName: "CXR",
    fieldTypeName: "Text"
  },
  ctScanOfChest: {
    fieldLabelName: "CT Scan of Chest",
    fieldTypeName: "Text"
  },
  sBiblirubin: {
    fieldLabelName: "S. bilirubin",
    fieldTypeName: "Text"
  },
  sgpt: {
    fieldLabelName: "SGPT",
    fieldTypeName: "Text"
  },
  sgot: {
    fieldLabelName: "SGOT",
    fieldTypeName: "Text"
  },
  bloodSugar: {
    fieldLabelName: "Blood Sugar(F/R)",
    fieldTypeName: "Text"
  },
  sCreatnine: {
    fieldLabelName: "S. Creatinine",
    fieldTypeName: "Text"
  },
  sElectrolytes: {
    fieldLabelName: "S. Electrolytes: Na+",
    fieldTypeName: "Text"
  },
  kPlus: {
    fieldLabelName: "K+",
    fieldTypeName: "Text"
  },
  cl: {
    fieldLabelName: "Cl",
    fieldTypeName: "Text"
  },
  hc03: {
    fieldLabelName: "HC03",
    fieldTypeName: "Text"
  },
  sptumForAfbSmear: {
    fieldLabelName: "Sptum for AFB Smear",
    fieldTypeName: "Text"
  },
  geneXpertOrMtb: {
    fieldLabelName: "GeneXpert/MTB",
    fieldTypeName: "Text"
  },
  sptumForCs: {
    fieldLabelName: "Sptum for C/S",
    fieldTypeName: "Text"
  },
  ecg: {
    fieldLabelName: "ECG",
    fieldTypeName: "Text"
  },
  echocardiogram: {
    fieldLabelName: "Echocardiogram",
    fieldTypeName: "Text"
  },
  usgChestOrAbdomen: {
    fieldLabelName: "USG Chest/Abdomen",
    fieldTypeName: "Text"
  },
  sprirometry: {
    fieldLabelName: "Sprirometry",
    fieldTypeName: "Text"
  },
  pleuralFluidStudy: {
    fieldLabelName: "Pleural fluid study",
    fieldTypeName: "Text"
  },
  fnacLungLesion: {
    fieldLabelName: "FNAC (Lung lesion/L.node)",
    fieldTypeName: "Text"
  },
  other: {
    fieldLabelName: "Other",
    fieldTypeName: "Text"
  }
};
