import React from "react";
import { Button, useDisclosure } from "@chakra-ui/react";
import { DocumentWithAttributes } from "app/api/type";
import { PrintableDischargeCertificatePage } from "../pages/printable-discharge-certificate.page";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";

interface PrintableDischargeCertificateComponentProps {
  document: DocumentWithAttributes;
}

export const PrintableDischargeCertificateComponent: React.FC<
  PrintableDischargeCertificateComponentProps
> = (props: PrintableDischargeCertificateComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  let documentAttribute: {
    [key: string]: { level: string; value: string };
  } = {};

  props.document.documentAttributes.map((item) => {
    let keyValue = item.fieldLabelName.replace(/\s/g, "");
    documentAttribute[keyValue] = {
      level: item.fieldLabelName,
      value: item.value
    };
  });

  const Document = (
    <PrintableDischargeCertificatePage
      data={props.document}
      documentAttribute={documentAttribute}
    />
  );
  const requestReport = `Discharge Certificate.pdf`;

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue">
        Print Discharge Certificate {props.document.policeCaseId}
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={requestReport}
      />
    </>
  );
};
