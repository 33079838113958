import React from "react";
import { RecordRequestLogTableComponent } from "../components/record-request-log-table.component";

interface RecordRequestLogPageProps {}

export const RecordRequestLogPage: React.FC<RecordRequestLogPageProps> = (
  props: RecordRequestLogPageProps
) => {
  return <RecordRequestLogTableComponent />;
};
