import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet
} from "@react-pdf/renderer";
import { env } from "app/config";
import dayjs from "dayjs";
import React from "react";
import { FormattedCertificate } from "../api/type";

interface InjuryCertificatePrintPageProps {
  data: FormattedCertificate;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    padding: "0.50in",
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "12.5%",
    objectFit: "contain",
    alignSelf: "center"
  },
  certificateName: {
    fontSize: 16,
    textTransform: "uppercase",
    // fontWeight: "bold",
    textAlign: "center"
  },
  hospitalInformation: {
    fontSize: 12,
    // fontWeight: s"semibold",
    textAlign: "center"
  },
  headerTop: {
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: "10px"
  },
  header: {
    marginBottom: 5,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row"
  },
  subTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginVertical: 10,
    alignSelf: "center"
  },
  table: {
    width: "100%",
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000"
  },
  tableHeader: {
    fontSize: 12,
    borderTop: "1px solid #000"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
    borderBottom: "1px solid #000"
  },
  serial: {
    width: "5%",
    borderRight: "1px solid #000"
  },
  typeOfInjury: {
    width: "20%",
    textAlign: "left",
    paddingHorizontal: 5,
    borderRight: "1px solid #000"
  },
  sizeOfInjury: {
    width: "20%",
    borderRight: "1px solid #000"
  },
  bodyInflictedPart: {
    paddingHorizontal: 5,
    width: "18%",
    borderRight: "1px solid #000"
  },
  typeOfWeapon: {
    width: "18%",
    borderRight: "1px solid #000"
  },
  natureOfInjury: {
    width: "18%"
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 8,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1,px solid #000"
  },
  labelText: {
    marginRight: 5,
    fontWeight: "bold",
    fontSize: 10
  },
  valueText: {
    flex: 1,
    fontSize: 10
  },
  dottedUnderline: {
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "dotted"
  },
  footerText: {
    fontSize: 10,
    fontWeight: "bold"
  },
  footerValue: {
    fontSize: 10
  },
  signature: {
    fontSize: 9,
    position: "absolute",
    right: 100,
    bottom: 60,
    alignSelf: "flex-end"
  },
  backgroundImage: {
    position: "absolute",
    top: "45%",
    width: "200px",
    opacity: 0.1,
    objectFit: "contain",
    alignSelf: "center"
  }
});

export const InjuryCertificatePrintPage: React.FC<
  InjuryCertificatePrintPageProps
> = (props: InjuryCertificatePrintPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Image style={styles.backgroundImage} src={env.hospitalLogo} fixed />
        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center"
          }}
        >
          <Image style={styles.logo} src="/images/bdgovtlogo.png" />
          <View style={{ width: "70%" }}>
            <View>
              <Text
                style={{
                  fontSize: 16,
                  alignSelf: "center"
                }}
              >
                {env.hospitalName}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 9,
                  alignSelf: "center",
                  fontWeight: 600
                }}
              >
                {env.hospitalName}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 9,
                  alignSelf: "center"
                }}
              >
                Address: {env.hospitalAddress}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 8,
                  alignSelf: "center"
                }}
              >
                Contact: {env.hospitalContact} | Email: {env.hospitalContact}
              </Text>
            </View>
          </View>
          <Image style={styles.logo} src={env.hospitalLogo} />
        </View>

        <View
          style={{
            borderTop: "1px solid #000",
            paddingTop: "8px",
            marginTop: "8px"
          }}
        >
          <View
            style={{
              fontSize: 12,
              alignSelf: "center",
              textTransform: "uppercase"
            }}
          >
            <Text>Injury Certificate</Text>
          </View>
          <View
            style={{
              fontSize: 12,
              alignSelf: "center"
            }}
          >
            <Text>Department of Casualty</Text>
          </View>
        </View>

        <View style={styles.headerTop}>
          <Text>
            Ref:-{" "}
            {props.data.nonTabularData.find(
              (record) => record.title === "Reference No"
            )?.value
              ? props.data.nonTabularData.find(
                  (record) => record.title === "Reference No"
                )?.value +
                ` (${props.data.certificateId} - ${props.data.policeCaseId})`
              : `(${props.data.certificateId} - ${props.data.policeCaseId})`}
          </Text>
          <Text>
            Issue Date:{" "}
            {props.data.nonTabularData.find(
              (record) => record.title === "Issue Date"
            )?.value ?? "N/A"}
          </Text>
        </View>

        <View style={[styles.header]}>
          <Text style={styles.labelText}>Patient Name:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "50%" }]}
          >
            {props.data.patient.name}
          </Text>
          <Text style={styles.labelText}>Age:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "20%" }]}
          >
            {props.data.patient.age}
          </Text>
        </View>
        <View style={[styles.header]}>
          <Text style={styles.labelText}>Sex:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "20%" }]}
          >
            {props.data.patient.gender}
          </Text>
          <Text style={styles.labelText}>Reg No:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "20%" }]}
          >
            {props.data.patient.patientId}
          </Text>
          <Text style={styles.labelText}>Registration Date:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "20%" }]}
          >
            {props.data.createdAt}
          </Text>
        </View>

        <View style={[styles.header]}>
          <Text style={styles.labelText}>Father's or Guardian's Name:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "30%" }]}
          >
            {props.data.patient.fatherOrGuardianName}
          </Text>
          <Text style={styles.labelText}>Spouse's Name:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "30%" }]}
          >
            {props.data.patient.spouseName ?? "N/A"}
          </Text>
        </View>

        <View style={[styles.header]}>
          <Text style={styles.labelText}>Address:</Text>
          <Text
            style={[styles.valueText, styles.dottedUnderline, { width: "90%" }]}
          >
            {props.data.patient.address}
          </Text>
        </View>

        <View style={styles.subTitle}>
          <Text>Injury Note:-</Text>
        </View>

        <View style={styles.table}>
          <View style={[styles.tableHeader, styles.row]} fixed>
            <Text style={styles.serial}>#</Text>
            <Text style={styles.typeOfInjury}>
              Type of Injury (Whether incised, lacerated, Bruise etc)
            </Text>
            <Text style={styles.sizeOfInjury}>
              Size of Each Injury (Length, Breadth, Depth in cm)
            </Text>
            <Text style={styles.bodyInflictedPart}>
              On what part of the body inflicted
            </Text>
            <Text style={styles.typeOfWeapon}>
              By what type of Weapon inflicted
            </Text>
            <Text style={styles.natureOfInjury}>
              Nature of Injury (Simple/Grievous)
            </Text>
          </View>

          {props.data.tabularData.map((records, index) => (
            <View style={styles.row} wrap={false}>
              <>
                <Text style={styles.serial}>{index + 1}</Text>
                <Text style={styles.typeOfInjury}>
                  {records.find((record) => record.label === "Type of Injury")
                    ?.value ?? "N/A"}
                </Text>
                <Text style={styles.sizeOfInjury}>
                  {records.find(
                    (record) =>
                      record.label ===
                      "Size of Injury (Length x Width x Depth in cm)"
                  )?.value ?? "N/A"}
                </Text>
                <Text style={styles.bodyInflictedPart}>
                  {records.find((record) => record.label === "Site of Injury")
                    ?.value ?? "N/A"}
                </Text>
                <Text style={styles.typeOfWeapon}>
                  {records.find((record) => record.label === "Type of Weapon")
                    ?.value ?? "N/A"}
                </Text>
                <Text style={styles.natureOfInjury}>
                  {records.find((record) => record.label === "Nature of Injury")
                    ?.value ?? "N/A"}
                </Text>
              </>
            </View>
          ))}
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginVertical: "10px"
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Text style={styles.footerText}> Time of Occurrence: </Text>
            <Text style={styles.footerValue}>
              {props.data.nonTabularData.find(
                (record) => record.title === "Time of Occurrence"
              )?.value ?? "N/A"}
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Text style={styles.footerText}> Color of the wound: </Text>
            <Text style={styles.footerValue}>
              {props.data.nonTabularData.find(
                (record) => record.title === "Color of the wound"
              )?.value ?? "N/A"}
            </Text>
          </View>
        </View>

        <View style={styles.signature}>
          <Text>Signature & Seal</Text>
          <Text>Name</Text>
          <Text>Designation</Text>
          <Text>BMDC No.</Text>
        </View>

        <View style={styles.footer} fixed>
          <View>
            <Text>Powered by Bindulogic Limited</Text>
          </View>
          <View>
            <Text>
              Printed By:{" "}
              {window.localStorage.getItem("locationName") || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
