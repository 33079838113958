import { Box, Link, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { env } from "app/config";

interface FooterComponentProps {}

export const FooterComponent: React.FC<FooterComponentProps> = (
  props: FooterComponentProps
) => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Box>
      <Stack
        direction={{ base: "column", md: "row", xl: "row" }}
        mt="3"
        justify="space-between"
        alignItems="center"
      >
        <Box>
          <Text fontSize={{ base: "xs", md: "sm" }}>
            &copy; {year} {env.hospitalName}
          </Text>
        </Box>
        <Box>
          <Text fontSize={{ base: "xs", md: "sm" }}>
            Powered by{" "}
            <Link color="blue.400" href="https://bindulogic.com/" isExternal>
              <Text as="span" fontSize={{ base: "xs", md: "sm" }}>
                Bindulogic Limited
              </Text>
            </Link>
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
