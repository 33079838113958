import React from "react";
import { Stack } from "@chakra-ui/react";
import { SearchCaseComponent } from "../components/search-case.component";

interface SearchCaseProps {}

export const SearchCasePage: React.FC<SearchCaseProps> = (
  props: SearchCaseProps
) => {
  return (
    <Stack>
      <SearchCaseComponent />
    </Stack>
  );
};
