import { IPage } from "app/types";
import { LoginPage } from "../pages/login.page";

export const authRoutes: IPage[] = [
  {
    name: "Login",
    link: "/login",
    content: <LoginPage />
  }
];
