import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  IconButton,
  Text,
  Flex
} from "@chakra-ui/react";
import { useCreateRecordRequestMutation } from "../api";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "modules/police-case/styles/chakra-react-datepicker.css";
import { useDropzone } from "react-dropzone";
import {
  FaCloudUploadAlt,
  FaRegFileImage,
  FaRegFilePdf,
  FaTrash
} from "react-icons/fa";

interface Props {}

export const CreateRecordRequestModal: React.FC<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [createRecordRequest, createRecordRequestResult] =
    useCreateRecordRequestMutation();

  const [requestSource, setRequestSource] = useState<string>();
  const [requestDocumentUrl, setRequestDocumentUrl] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [requestDocumentText, setRequestDocumentText] = useState<string>();
  const [deadline, setDeadline] = useState<Date>();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.length) {
      setFiles(() => [
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
      ]);
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("File reading was aborted.");
      reader.onerror = () => console.log("File reading has failed.");
      reader.onload = () => {
        setUploadedFile(file);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const removeFile = (name: string) => {
    setFiles((files) => files.filter((file) => file.name !== name));
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    accept: { "image/jpeg": [], "image/png": [] },
    multiple: false,
    useFsAccessApi: false // Bug Fix: https://github.com/react-dropzone/react-dropzone/issues/1190
  });

  const dropzoneBackgroundColor = isDragActive
    ? isDragAccept
      ? "green.50"
      : "red.50"
    : "gray.100";

  const handleCreateRecordRequest = () => {
    if (requestSource && deadline && uploadedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        const base64Image = reader.result as string;
        createRecordRequest({
          data: {
            recordRequest: {
              deadline,
              requestSource,
              requestDocumentUrl: base64Image, // Set base64 representation here
              requestDocumentText: requestDocumentText || null,
              recordRequestStatusName: "Initialized"
            }
          }
        });
      };
    }
  };

  useEffect(() => {
    if (createRecordRequestResult.data) {
      toast({
        title: "Success",
        description: "Added record request",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });
      onClose();
    }
  }, [createRecordRequestResult]);

  const uploadIcon = <FaCloudUploadAlt size="100px" color="blue.500" />;

  const instructionText = "Drag files here or click to upload";
  const instructionSubtext = "Drop file to upload";
  const dragAcceptText = "Drag file here";
  const dragRejectText = "Cannot upload this";

  return (
    <>
      <Button
        size={{ base: "xs", lg: "sm" }}
        colorScheme={"blue"}
        onClick={onOpen}
      >
        Record New Request
      </Button>

      <Modal
        size={{ base: "xs", md: "xl", lg: "xl", xl: "3xl" }}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{ base: "xs", md: "md", lg: "lg" }}>
            Create New Request
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <FormControl isRequired>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Request Source
                </FormLabel>
                <Input
                  size={{ base: "xs", lg: "sm" }}
                  placeholder="Enter source of record request"
                  value={requestSource}
                  onChange={(event) => {
                    if (event) {
                      setRequestSource(event.target.value);
                    }
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Request Criteria
                </FormLabel>
                <Textarea
                  size="sm"
                  placeholder="Enter patient reference data"
                  value={requestDocumentText}
                  onChange={(event) => {
                    if (event) {
                      setRequestDocumentText(event.target.value);
                    }
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                  Deadline
                </FormLabel>
                <DatePicker
                  placeholderText="Select Date"
                  selected={deadline}
                  onChange={(date) => date && setDeadline(date)}
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={new Date()}
                />
              </FormControl>
            </Stack>
            <>
              <Text
                fontSize={{ base: "xs", lg: "md" }}
                paddingTop="10px"
                paddingBottom="10px"
              >
                Upload Letter
              </Text>
              <Flex
                align="center"
                justify="center"
                bgColor={dropzoneBackgroundColor}
                color={isDragActive ? "gray.900" : "gray.800"}
                _hover={{
                  color: "gray.900",
                  borderColor: "gray.500"
                }}
                borderStyle="solid"
                borderWidth="2px"
                borderColor={isDragActive ? "gray.500" : "gray.300"}
                borderRadius="lg"
                width="100%"
                height="40"
                minH="20%"
                cursor="pointer"
                {...getRootProps({ className: "dropzone" })}
              >
                <input {...getInputProps()} />

                <Stack alignItems={"center"} textAlign="center" p={6}>
                  {uploadIcon}
                  {!isDragActive && (
                    <Text fontSize={{ base: "xs", lg: "md" }}>
                      {instructionText}
                    </Text>
                  )}
                  {!isDragActive &&
                    (instructionSubtext ?? (
                      <Text fontSize={{ base: "xs", lg: "md" }}>
                        {instructionSubtext}
                      </Text>
                    ))}
                  {isDragAccept && (
                    <Text fontSize={{ base: "xs", lg: "md" }}>
                      {dragAcceptText}
                    </Text>
                  )}
                  {isDragReject && (
                    <Text fontSize={{ base: "xs", lg: "md" }}>
                      {dragRejectText}
                    </Text>
                  )}
                </Stack>
              </Flex>
              <Stack>
                {files.map((file: File, index) => {
                  if (files.length === index + 1)
                    return (
                      <Stack
                        marginTop="20px"
                        direction="row"
                        border={"solid"}
                        borderWidth="2px"
                        borderColor={"gray.400"}
                        _hover={{
                          borderColor: "gray.600"
                          // backgroundColor: "gray.100"
                        }}
                        align="center"
                        key={file.name}
                        p="3"
                        justify={"space-between"}
                        borderRadius="lg"
                      >
                        <Stack direction="row" align="center" px="3">
                          {file.type === "application/pdf" ? (
                            <FaRegFilePdf />
                          ) : (
                            <FaRegFileImage />
                          )}
                          <Text>{file.name}</Text>
                        </Stack>

                        <IconButton
                          aria-label="Delete"
                          icon={<FaTrash />}
                          w="10px"
                          colorScheme="red"
                          size="sm"
                          onClick={() => removeFile(file.name)}
                        />
                      </Stack>
                    );
                  else return <></>;
                })}
              </Stack>
            </>
          </ModalBody>

          <ModalFooter>
            <Button
              size={{ base: "xs", lg: "sm" }}
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size={{ base: "xs", lg: "sm" }}
              colorScheme="blue"
              onClick={handleCreateRecordRequest}
              isDisabled={!uploadedFile}
              isLoading={createRecordRequestResult.isLoading}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
