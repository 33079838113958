import React from "react";
import { Stack, FormControl, Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useGetAllCasesCountQuery } from "../api";

interface DashboardComponentProps {}

export const DashboardComponent: React.FC<DashboardComponentProps> = (
  props: DashboardComponentProps
) => {
  const getAllCasesStatResult = useGetAllCasesCountQuery({});
  return (
    <Stack>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 4 }} spacing="10px">
        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <Text fontSize={{ base: "sm", md: "sm", lg: "md" }}>Total Cases</Text>
          <Box>
            <Stack>
              <Text
                fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                fontWeight="extrabold"
              >
                {getAllCasesStatResult.data?.patientStatistics.totalCases
                  ? getAllCasesStatResult.data?.patientStatistics.totalCases
                  : 0}
              </Text>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <Text fontSize={{ base: "sm", md: "sm", lg: "md" }}>
            Total OPD Patients
          </Text>
          <Box>
            <Stack>
              <Text
                fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                fontWeight="extrabold"
              >
                {getAllCasesStatResult.data?.patientStatistics.OPD
                  ? getAllCasesStatResult.data?.patientStatistics.OPD
                  : 0}
              </Text>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <Text fontSize={{ base: "sm", md: "sm", lg: "md" }}>
            Total IPD Patients
          </Text>
          <Box>
            <Stack>
              <Text
                fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                fontWeight="extrabold"
              >
                {getAllCasesStatResult.data?.patientStatistics.IPD
                  ? getAllCasesStatResult.data?.patientStatistics.IPD
                  : 0}
              </Text>
            </Stack>
          </Box>
        </FormControl>

        <FormControl borderRadius={"md"} p="6" boxShadow={"lg"} bg="white">
          <Text fontSize={{ base: "sm", md: "sm", lg: "md" }}>
            Total Dead Patients
          </Text>
          <Box>
            <Stack>
              <Text
                fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                fontWeight="extrabold"
              >
                {getAllCasesStatResult.data?.patientStatistics.Dead
                  ? getAllCasesStatResult.data?.patientStatistics.Dead
                  : 0}
              </Text>
            </Stack>
          </Box>
        </FormControl>
      </SimpleGrid>
    </Stack>
  );
};
