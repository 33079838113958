import React from "react";
import { Stack } from "@chakra-ui/react";
import { UserListTableComponent } from "../components/user-list-table.component";

interface Props {}

export const UserListPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <UserListTableComponent />
    </Stack>
  );
};
