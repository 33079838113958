import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FileDropzoneComponent } from "modules/core/components/file-dropzone.component";

import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt, FaRegFileImage } from "react-icons/fa";

type Props =
  | {
      fieldTypeName: "Select";
      fieldLabelName: string;
      form: { [key: string]: string };
      setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
      fieldValues: { id: string; value: string; code: string | null }[] | [];
    }
  | {
      fieldTypeName: "Text";
      fieldLabelName: string;
      form: { [key: string]: string };
      setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    }
  | {
      fieldTypeName: "Image";
      fieldLabelName: string;
      form: { [key: string]: string };
      setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    }
  | {
      fieldTypeName: "Checkbox";
      fieldLabelName: string;
      form: { [key: string]: string };
      setForm: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
    };

export const FormFieldComponent = (props: Props) => {
  const toast = useToast();

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>();

  const [options, setOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  useEffect(() => {
    if (props.fieldTypeName === "Select") {
      const destructuredOptions = props.fieldValues.map((fieldValue) => ({
        label: fieldValue.value,
        value: fieldValue.value
      }));
      setOptions(destructuredOptions);
    }
  }, []);

  if (props.fieldTypeName === "Text") {
    return (
      <FormControl>
        <FormLabel>{props.fieldLabelName}</FormLabel>
        <Input
          value={props.form[props.fieldLabelName]}
          defaultValue="n/a"
          onChange={(event) => {
            props.setForm({
              ...props.form,
              [props.fieldLabelName]: event.target.value
            });
          }}
        />
      </FormControl>
    );
  } else if (props.fieldTypeName === "Select") {
    return (
      <FormControl>
        <FormLabel>{props.fieldLabelName}</FormLabel>
        <Select
          options={options}
          value={selectedOption}
          defaultInputValue={options[0]?.label || ""}
          onChange={(event) => {
            if (event) {
              setSelectedOption({
                label: event.label,
                value: event.value
              });
              props.setForm({
                ...props.form,
                [props.fieldLabelName]: event.value
              });
            }
          }}
        />
      </FormControl>
    );
  } else if (props.fieldTypeName === "Image") {
    return (
      <Stack>
        <FileDropzoneComponent
          allowedFileTypes={{
            "image/*": [".png", ".jpg", ".jpeg"]
          }}
          maxFiles={1}
          onDrop={(files: File[]) => {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event && event.target && event.target.result) {
                props.setForm({
                  ...props.form,
                  [props.fieldLabelName]: event.target.result as string
                });

                toast({
                  title: "Uploaded Image",
                  description: `${files[0].name}`,
                  position: "top",
                  status: "success",
                  duration: 4000,
                  isClosable: true
                });
              }
            };
            reader.readAsDataURL(file);
          }}
          allowMultiple={false}
          uploadIcon={<FaCloudUploadAlt size="100px" color="blue.500" />}
          instructionText="Drag files here or click to upload"
          instructionSubtext="Drop file to upload"
          dragAcceptText="Drag file here"
          dragRejectText="Cannot upload this"
        />

        {props.form[props.fieldLabelName] && (
          <Stack
            direction="row"
            border={"solid"}
            borderWidth="2px"
            borderColor={"gray.400"}
            align="center"
            p="3"
            justify={"space-between"}
            borderRadius="lg"
          >
            <Stack direction="row" align="center" px="3">
              <FaRegFileImage />
              <Text>{props.fieldLabelName}</Text>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  } else if (props.fieldTypeName === "Checkbox") {
    return (
      <Stack spacing={5} direction="row">
        <Checkbox
          onChange={(event) => {
            props.setForm({
              ...props.form,
              [props.fieldLabelName]: event.target.checked ? "true" : "false"
            });
          }}
        >
          {props.fieldLabelName}
        </Checkbox>
      </Stack>
    );
  } else {
    return <></>;
  }
};
