import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  GridItem,
  Heading,
  IconButton,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Tooltip,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import { useGetPoliceCaseDetailsQuery } from "../api";
import { PoliceCaseWithDetails } from "app/api/type";
import { FaDownload, FaFilePdf, FaImage, FaFile } from "react-icons/fa";
import { AddPatientComponent } from "./add-patient.component";
import { useNavigate } from "react-router-dom";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";
import { DocumentViewerModalComponent } from "./document-viewer-modal.component";
import { InformationCardComponent } from "./information-card.component";

interface CaseDetailsComponentProps {
  policeCaseId: string;
}

export const CaseDetailsComponent: React.FC<CaseDetailsComponentProps> = (
  props: CaseDetailsComponentProps
) => {
  const navigate = useNavigate();
  const getCaseDetailsResult = useGetPoliceCaseDetailsQuery({
    id: props.policeCaseId
  });

  const [shouldShowAddPatientButton, setShouldShowAddPatientButton] =
    useState<boolean>(true);

  const [policeCaseWithDetails, setPoliceCaseWithDetails] =
    React.useState<PoliceCaseWithDetails | null>(null);

  useEffect(() => {
    if (getCaseDetailsResult.isSuccess) {
      setPoliceCaseWithDetails(getCaseDetailsResult.data.policeCaseWithDetails);
    }
  }, [getCaseDetailsResult]);

  useEffect(() => {
    if (getCaseDetailsResult.isSuccess) {
      if (
        getCaseDetailsResult.data.policeCaseWithDetails.policeCase
          .maximumPatientCount >
          getCaseDetailsResult.data.policeCaseWithDetails.subjects.length ||
        getCaseDetailsResult.data.policeCaseWithDetails.policeCase
          .maximumPatientCount === -1
      ) {
        setShouldShowAddPatientButton(true);
      } else {
        setShouldShowAddPatientButton(false);
      }
    }
  }, [getCaseDetailsResult]);

  return policeCaseWithDetails ? (
    <Stack spacing="12">
      <Stack spacing="6">
        <Heading
          color="gray.500"
          fontSize={{ base: "sm", md: "md", lg: "md", xl: "lg" }}
        >
          Case {policeCaseWithDetails.policeCase.id}
        </Heading>

        <FormControl
          borderRadius={"md"}
          p="6"
          boxShadow={"md"}
          bg="white"
          width="100%"
        >
          <FormLabel
            fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
            fontWeight="bold"
          >
            Police Case Remarks
          </FormLabel>
          <Stack>
            <Text fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}>
              {policeCaseWithDetails.policeCase.remarks
                ? policeCaseWithDetails.policeCase.remarks
                : "No remarks were provided"}
            </Text>
          </Stack>
        </FormControl>
      </Stack>

      <Stack spacing="6">
        <Stack direction={"row"} justify={"space-between"} align={"center"}>
          <Heading fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}>
            Patients
          </Heading>
          {shouldShowAddPatientButton ? (
            <AddPatientComponent policeCaseId={props.policeCaseId} />
          ) : (
            <></>
          )}
        </Stack>

        <Stack>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} gap={4}>
            {policeCaseWithDetails.subjects.map((subject, index) => (
              <GridItem key={subject.patient.id}>
                <Stack
                  backgroundColor="blue.50"
                  justify="space-between"
                  align={"center"}
                  direction="row"
                  spacing="2"
                  px={4}
                  py={4}
                  borderRadius={"lg"}
                  shadow="md"
                >
                  <Text fontSize={{ base: "sm", md: "sm", lg: "md" }}>
                    {subject.person.name}
                  </Text>
                  <Button
                    size={{ base: "xs", lg: "sm" }}
                    onClick={() => {
                      const locationCategory = window.localStorage.getItem(
                        "locationCategory"
                      ) as string;
                      navigate(
                        `/${getDashboardName(
                          locationCategory
                        )}/document/upload?caseId=${
                          props.policeCaseId
                        }&subjectIndex=${index}`
                      );
                    }}
                  >
                    Upload Documents
                  </Button>
                </Stack>
              </GridItem>
            ))}
          </SimpleGrid>
        </Stack>
      </Stack>

      <Stack
        spacing={6}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px"
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            backgroundColor: "gray.700"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "gray.600",
            borderRadius: "12px"
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "gray.500"
          }
        }}
      >
        <Heading fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}>
          Documents
        </Heading>
        <Stack direction="row" width="100%">
          <Stack width="100%">
            {policeCaseWithDetails.subjects.map((subject) => {
              return (
                <Stack
                  p="6"
                  borderWidth="3px"
                  borderColor="gray.200"
                  borderRadius="md"
                  borderStyle={"dashed"}
                >
                  <Text
                    fontSize={{ base: "sm", md: "sm", lg: "md", xl: "lg" }}
                    color="gray.700"
                  >
                    {subject.person.name}
                  </Text>
                  <SimpleGrid columns={1} gap={4}>
                    {subject.documentsWithAttributes.map((document) => (
                      <GridItem key={document.id}>
                        <InformationCardComponent document={document} />
                      </GridItem>
                    ))}
                  </SimpleGrid>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Center width="100%" height={"95vh"}>
      <Spinner />
    </Center>
  );
};
