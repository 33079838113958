import { Tag } from "@chakra-ui/react";
import { RecordRequestStatusName } from "app/api/type";
import React from "react";

interface Props {
  recordRequestStatusName: RecordRequestStatusName;
}

export const RecordRequestStatusTag: React.FC<Props> = (props: Props) => {
  let backgroundColor = "blue";
  if (props.recordRequestStatusName === "Initialized")
    backgroundColor = "blue.500";
  if (props.recordRequestStatusName === "Pending")
    backgroundColor = "yellow.500";
  if (props.recordRequestStatusName === "Approved")
    backgroundColor = "green.400";
  if (props.recordRequestStatusName === "Fulfilled")
    backgroundColor = "green.800";
  if (props.recordRequestStatusName === "Missing")
    backgroundColor = "orange.500";
  if (props.recordRequestStatusName === "Rejected") backgroundColor = "red.500";

  return (
    <Tag backgroundColor={backgroundColor} color="white" fontWeight="bold">
      {props.recordRequestStatusName}
    </Tag>
  );
};
