import React from "react";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { CaseLogTableComponent } from "../components/case-log-table.component";

interface Props {}

export const CaseLogPage: React.FC<Props> = (props: Props) => {
  const { caseId } = useParams();

  return (
    <Stack>
      <CaseLogTableComponent caseId={caseId} />
    </Stack>
  );
};
