import React from "react";
import { Box, Text, Flex, Stack, Link, Image } from "@chakra-ui/react";
import { DocumentWithAttributes } from "app/api/type";

type Props = {
  document: DocumentWithAttributes;
};

export const InformationCardComponent = (props: Props) => {
  const { document } = props;

  return (
    <Stack
      spacing={2}
      bgColor="white"
      borderRadius={"md"}
      boxShadow={"xs"}
      p={3}
      height="auto"
    >
      <Text fontSize="sm" fontWeight="bold" color="gray.500" isTruncated>
        {document.name}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {`Created at: ${document.createdAt}`}
      </Text>

      <Stack spacing={1}>
        {document.documentAttributes.map((attribute, index) => (
          <Stack spacing={-1}>
            {attribute.value && (
              <>
                <Text fontWeight="bold" fontSize={"xs"}>
                  {attribute.fieldLabelName}
                </Text>
                {attribute.fieldTypeName === "Image" ? (
                  <Image
                    p={1}
                    src={attribute.value}
                    objectFit={"contain"}
                    width="100%"
                  />
                ) : (
                  <Text fontSize="xs">{attribute.value}</Text>
                )}
              </>
            )}
          </Stack>
        ))}

        {props.document.remarks && (
          <Stack spacing={-1}>
            {" "}
            <Text fontWeight="bold" fontSize={"xs"}>
              Remarks
            </Text>
            <Text fontSize="xs">{document.remarks}</Text>{" "}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
