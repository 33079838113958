import React from "react";
import { Stack } from "@chakra-ui/react";
import { CreateUserFormComponent } from "../components/create-user-form.component";

interface Props {}

export const CreateUserPage: React.FC<Props> = (props: Props) => {
  return (
    <Stack>
      <CreateUserFormComponent />
    </Stack>
  );
};
