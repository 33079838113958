import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDocumentTitle } from "hooks/use-document-title.hook";

import { useEffect } from "react";
import { env } from "app/config";

import { useLoginMutation } from "../api";

import { DecorationImageComponent } from "./decoration-image.component";
import { OrganizationComponent } from "../../core/components/organization.component";

type LoginFormValues = {
  email: string;
  password: string;
};

const loginFormSchema = z.object({
  email: z.string().email(),

  password: z
    .string()
    .min(8, { message: "Password must be 8 or more characters long" })
});

export const LoginFormComponent = () => {
  const navigate = useNavigate();
  const [loginRequest, loginResult] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema)
  });

  useEffect(() => {
    if (loginResult.isSuccess) {
      window.localStorage.setItem("jwt", loginResult.data.accessToken);
      window.localStorage.setItem("id", loginResult.data.userId);

      navigate("/location/select");
    }
  }, [loginResult, navigate]);

  const handleLogin = handleSubmit((data) => {
    loginRequest({
      data: {
        user: {
          email: data.email,
          password: data.password
        }
      }
    });
  });

  useDocumentTitle("Login | " + env.appName);

  return (
    <Box boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" p={5} borderRadius="lg">
      <form onSubmit={handleLogin}>
        <Center>
          <Stack spacing={4} w={"full"} maxW={"lg"}>
            <DecorationImageComponent />
            <Heading
              fontSize={{ base: "md", md: "xl", lg: "2xl" }}
              textAlign="center"
            >
              {env.appName}
            </Heading>
            <Heading
              fontSize={{ base: "md", md: "xl", lg: "2xl" }}
              textAlign="center"
            >
              Login
            </Heading>
            <FormControl id="email" isInvalid={errors.email ? true : false}>
              <FormLabel fontSize={{ base: "xs", lg: "md" }}>Email</FormLabel>
              <Input
                {...register("email")}
                size={{ base: "xs", lg: "sm" }}
                type="string"
                placeholder="johndoe@gmail.com"
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              id="password"
              isInvalid={errors.password ? true : false}
            >
              <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                Password
              </FormLabel>
              <Input
                {...register("password")}
                size={{ base: "xs", lg: "sm" }}
                placeholder="********"
                type="password"
              />
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
            <Button
              size={{ base: "xs", lg: "sm" }}
              colorScheme="blue"
              variant="solid"
              type="submit"
            >
              Login
            </Button>
            <Divider />
            <OrganizationComponent />
          </Stack>
        </Center>
      </form>
    </Box>
  );
};
