import React from "react";
import { Stack } from "@chakra-ui/react";
import { DashboardComponent } from "../components/dashboard.component";

interface DashboardPageProps {}

export const DashboardPage: React.FC<DashboardPageProps> = (
  props: DashboardPageProps
) => {
  return (
    <Stack>
      <DashboardComponent />
    </Stack>
  );
};
