import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text
} from "@chakra-ui/react";
import { Log, Person, PoliceCase, User, Patient } from "app/api/type";
import { useLazySearchLogQuery } from "../api";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { LogComponent } from "./log.component";
import { useSearchParams } from "react-router-dom";

interface RecordRequestLogTableComponentProps {}

export const RecordRequestLogTableComponent: React.FC<
  RecordRequestLogTableComponentProps
> = (props: RecordRequestLogTableComponentProps) => {
  const [searchLogs, searchLogsResult] = useLazySearchLogQuery();
  const [searchParams] = useSearchParams();

  const [logs, setLogs] = useState<
    {
      log: Log;
      userWithDetails: {
        user: Omit<User, "password">;
        person: Person;
      };
      patientWithDetails: {
        patient: Patient;
        person: Person;
      } | null;
      policeCase: PoliceCase | null;
    }[]
  >([]);

  const [recordRequestId, setrecordRequestId] = useState<string>(
    searchParams.get("recordRequestId") as string
  );

  useEffect(() => {
    if (recordRequestId) {
      searchLogs({
        searchBy: "recordRequest",
        searchValue: recordRequestId
      });
    }
  }, []);

  useEffect(() => {
    if (searchLogsResult.isSuccess) {
      setLogs(searchLogsResult.data.logsWithDetails);
    }
  }, [searchLogsResult]);

  const handleSearch = () => {
    if (recordRequestId) {
      searchLogs({ searchBy: "recordRequest", searchValue: recordRequestId });
    }
  };

  return (
    <Stack spacing="4">
      <PageCardComponent>
        <FormControl>
          <FormLabel fontSize={{ base: "xs", md: "sm", lg: "md" }}>
            Record Request ID
          </FormLabel>
          <Stack direction="row">
            <Input
              size={{ base: "xs", lg: "sm" }}
              value={recordRequestId}
              onChange={(event) => {
                setrecordRequestId(event.target.value);
              }}
            />
            <Button
              size={{ base: "xs", lg: "sm" }}
              onClick={handleSearch}
              isLoading={searchLogsResult.isLoading}
              isDisabled={!recordRequestId}
            >
              Search
            </Button>
          </Stack>
        </FormControl>
      </PageCardComponent>

      {logs.map((log) => {
        return <LogComponent logWithDetails={log} />;
      })}
      {logs.length === 0 ? (
        <Center width="100%" height="128px">
          <Text color="gray.500">No logs found</Text>
        </Center>
      ) : (
        <></>
      )}
    </Stack>
  );
};
