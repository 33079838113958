import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { Patient, Person, PoliceCaseWithDetails } from "app/api/type";
import { env } from "app/config";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { useAddPatientDocumentToPoliceCaseMutation } from "../api";
import { DischargeCertificateFieldInformation } from "../constants/discharge-certificate-field-information";

interface DischargeCertificateModalComponentProps {
  person: Person;
  patient: Patient;
  policeCaseWithDetails: PoliceCaseWithDetails;
  isDisable: boolean;
}

type TransformedData = {
  fieldLabelName: string;
  fieldTypeName: string;
  code: string | null;
  value: string;
};

export const DischargeCertificateModalComponent: React.FC<
  DischargeCertificateModalComponentProps
> = (props: DischargeCertificateModalComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const patientCaseProfile = props.policeCaseWithDetails.subjects.map(
    (subject) => {
      return subject.patientCaseProfile;
    }
  );

  const [addDocument, addDocumentResult] =
    useAddPatientDocumentToPoliceCaseMutation();

  const [dateOfDischarge, setdateOfDischarge] = useState<string>();

  const [formData, setFormData] = useState({
    name: props.person.name || "",
    age: props.person.dateOfBirth || "",
    gender: props.person.gender || "",
    hospitalRegistrationNo: props.patient.id || "",

    fatherOrHusbandName: props.person.fatherName || "",
    spouseName: props.person.spouseName || "",
    phone: props.person.contactNumber || "",
    patientNid: props.person.nationalIdNumber || "",

    religion: props.person.religionName || "",
    address: props.person.address?.addressLine1 || "",

    patientCategory: patientCaseProfile[0].patientCategoryName
      ? patientCaseProfile[0].patientCategoryName
      : "",
    admissionDepartment: patientCaseProfile[0].admissionDepartment
      ? (patientCaseProfile[0].admissionDepartment as string)
      : "",
    unit: "",
    wardOrCabin: patientCaseProfile[0].ward ? patientCaseProfile[0].ward : "",

    bedNo: "",
    diseaseDescription: patientCaseProfile[0].disease
      ? patientCaseProfile[0].disease
      : "",
    unitProfessor: "",
    diagnosisIcdCode: "",

    dateOfAdmission: patientCaseProfile[0].admissionDate
      ? patientCaseProfile[0].admissionDate
      : new Date().toISOString(),
    dateOfDischarge: dateOfDischarge
      ? dateOfDischarge
      : new Date().toISOString(),
    counterSignature: "",
    signature: "",

    drOneName: "",
    drOneDesignation: "",
    drOneRegNo: "",
    drOneSeal: "",

    drTwoName: "",
    drTwoDesignation: "",
    drTwoRegNo: "",
    drTwoSeal: "",

    cbc: "",
    esr: "",
    tcOrWbc: "",
    dc: "",

    cxr: "",
    ctScanOfChest: "",
    sBiblirubin: "",
    sgpt: "",

    sgot: "",
    bloodSugar: "",
    sCreatnine: "",
    sElectrolytes: "",

    kPlus: "",
    cl: "",
    hc03: "",
    sptumForAfbSmear: "",

    geneXpertOrMtb: "",
    sptumForCs: "",
    ecg: "",
    echocardiogram: "",

    usgChestOrAbdomen: "",
    sprirometry: "",
    pleuralFluidStudy: "",
    fnacLungLesion: "",

    other: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const transformedData: TransformedData[] = Object.entries(formData).map(
      ([key, value]) => ({
        fieldLabelName:
          DischargeCertificateFieldInformation[key].fieldLabelName,
        fieldTypeName: DischargeCertificateFieldInformation[key].fieldTypeName,
        code: null,
        value: value as string
      })
    );
    addDocument({
      data: {
        policeCaseId: props.policeCaseWithDetails.policeCase.id,
        patientId: props.patient.id,
        documentTypeName: "Discharge Certificate",
        documentAttributes: transformedData
      }
    });
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size={"2xl"}
        padding={2}
        isDisabled={props.isDisable}
      >
        Generate Discharge Certificate
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit}>
            <ModalHeader textAlign={"center"}>
              <Text fontSize={"3xl"}>Discharge Certificate</Text>
              <Text fontSize={"xl"}>Department of Casualty</Text>
              <Text>{env.hospitalName}</Text>
              <Text>{env.hospitalAddress}</Text>
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Stack spacing="4">
                <Heading
                  fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
                >
                  Patient Information
                </Heading>
                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Patient's Name</FormLabel>
                    <Input
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Age</FormLabel>
                    <Input
                      name="age"
                      value={dayjs(formData.age).format("DD/MM/YYYY")}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize={{ base: "xs", lg: "md" }}>
                      Gender
                    </FormLabel>
                    <Input name="gender" value={formData.gender} isDisabled />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Hospital Registration No</FormLabel>
                    <Input
                      name="hospitalRegistrationNo"
                      value={formData.hospitalRegistrationNo}
                      isDisabled
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Father's/Husband Name</FormLabel>
                    <Input
                      name="fatherOrHusbandName"
                      value={formData.fatherOrHusbandName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Spouse's Name</FormLabel>
                    <Input
                      name="spouseName"
                      value={formData.spouseName}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Patient National ID</FormLabel>
                    <Input
                      name="patientNid"
                      value={formData.patientNid}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Religion</FormLabel>
                    <Input
                      name="religion"
                      value={formData.religion}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Address</FormLabel>
                    <Input
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Heading
                  fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
                  py="2"
                >
                  Admission Information
                </Heading>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Patient Category</FormLabel>
                    <Input
                      name="patientCategory"
                      value={formData.patientCategory}
                      isDisabled
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Admission Department</FormLabel>
                    <Input
                      name="admissionDepartment"
                      value={formData.admissionDepartment}
                      isDisabled
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Unit</FormLabel>
                    <Input
                      name="unit"
                      value={formData.unit}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Ward/Cabin</FormLabel>
                    <Input
                      name="wardOrCabin"
                      value={formData.wardOrCabin}
                      isDisabled
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Bed No</FormLabel>
                    <Input
                      name="bedNo"
                      value={formData.bedNo}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Disease/Problem Description</FormLabel>
                    <Input
                      name="diseaseDescription"
                      value={formData.diseaseDescription}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Unit Chief Prof./Asso. Prof</FormLabel>
                    <Input
                      name="unitProfessor"
                      value={formData.unitProfessor}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Diagnosis with ICD Code</FormLabel>
                    <Input
                      name="diagnosisIcdCode"
                      value={formData.diagnosisIcdCode}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Date of Admission</FormLabel>
                    <Input
                      name="dateOfAdmission"
                      value={dayjs(formData.dateOfAdmission).format(
                        "DD/MM/YYYY hh:mm A"
                      )}
                      isDisabled
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date of Discharge</FormLabel>
                    <DatePicker
                      selected={
                        dateOfDischarge ? new Date(dateOfDischarge) : new Date()
                      }
                      onChange={(date) =>
                        date && setdateOfDischarge(date.toISOString())
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Counter Signature</FormLabel>
                    <Input
                      name="counterSignature"
                      value={formData.counterSignature}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Signature</FormLabel>
                    <Input
                      name="signature"
                      value={formData.signature}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Heading
                  fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
                  py="2"
                >
                  Doctor Information
                </Heading>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Dr. 1 Name</FormLabel>
                    <Input
                      name="drOneName"
                      value={formData.drOneName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dr. 1 Designation</FormLabel>
                    <Input
                      name="drOneDesignation"
                      value={formData.drOneDesignation}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dr. 1 BMDC Reg. No</FormLabel>
                    <Input
                      name="drOneRegNo"
                      value={formData.drOneRegNo}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dr. 1 Seal</FormLabel>
                    <Input
                      name="drOneSeal"
                      value={formData.drOneSeal}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Dr. 2 Name</FormLabel>
                    <Input
                      name="drTwoName"
                      value={formData.drTwoName}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dr. 2 Designation</FormLabel>
                    <Input
                      name="drTwoDesignation"
                      value={formData.drTwoDesignation}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Dr. 2 BMDC Reg. No</FormLabel>
                    <Input
                      name="drTwoRegNo"
                      value={formData.drTwoRegNo}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Dr. 2 Seal</FormLabel>
                    <Input
                      name="drTwoSeal"
                      value={formData.drTwoSeal}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Heading
                  fontSize={{ base: "sm", md: "sm", lg: "md", xl: "md" }}
                  py="2"
                >
                  Others Information
                </Heading>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>CBC</FormLabel>
                    <Input
                      name="cbc"
                      value={formData.cbc}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>ESR</FormLabel>
                    <Input
                      name="esr"
                      value={formData.esr}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>TC/WBC</FormLabel>
                    <Input
                      name="tcOrWbc"
                      value={formData.tcOrWbc}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>DC</FormLabel>
                    <Input
                      name="dc"
                      value={formData.dc}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>CXR</FormLabel>
                    <Input
                      name="cxr"
                      value={formData.cxr}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>CT Scan of Chest</FormLabel>
                    <Input
                      name="ctScanOfChest"
                      value={formData.ctScanOfChest}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>S. bilirubin</FormLabel>
                    <Input
                      name="sBiblirubin"
                      value={formData.sBiblirubin}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>SGPT</FormLabel>
                    <Input
                      name="sgpt"
                      value={formData.sgpt}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>SGOT</FormLabel>
                    <Input
                      name="sgot"
                      value={formData.sgot}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Blood Sugar(F/R)</FormLabel>
                    <Input
                      name="bloodSugar"
                      value={formData.bloodSugar}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>S. Creatinine</FormLabel>
                    <Input
                      name="sCreatnine"
                      value={formData.sCreatnine}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>S. Electrolytes: Na+</FormLabel>
                    <Input
                      name="sElectrolytes"
                      value={formData.sElectrolytes}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>K+</FormLabel>
                    <Input
                      name="kPlus"
                      value={formData.kPlus}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Cl</FormLabel>
                    <Input
                      name="cl"
                      value={formData.cl}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>HC03</FormLabel>
                    <Input
                      name="hc03"
                      value={formData.hc03}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Sptum for AFB Smear</FormLabel>
                    <Input
                      name="sptumForAfbSmear"
                      value={formData.sptumForAfbSmear}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>GeneXpert/MTB</FormLabel>
                    <Input
                      name="geneXpertOrMtb"
                      value={formData.geneXpertOrMtb}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Sptum for C/S</FormLabel>
                    <Input
                      name="sptumForCs"
                      value={formData.sptumForCs}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>ECG</FormLabel>
                    <Input
                      name="ecg"
                      value={formData.ecg}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Echocardiogram</FormLabel>
                    <Input
                      name="echocardiogram"
                      value={formData.echocardiogram}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>USG Chest/Abdomen</FormLabel>
                    <Input
                      name="usgChestOrAbdomen"
                      value={formData.usgChestOrAbdomen}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Sprirometry</FormLabel>
                    <Input
                      name="sprirometry"
                      value={formData.sprirometry}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Pleural fluid study</FormLabel>
                    <Input
                      name="pleuralFluidStudy"
                      value={formData.pleuralFluidStudy}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>FNAC (Lung lesion/L.node)</FormLabel>
                    <Input
                      name="fnacLungLesion"
                      value={formData.fnacLungLesion}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>

                <Stack
                  direction={{
                    base: "column",
                    md: "row",
                    lg: "row",
                    xl: "row"
                  }}
                >
                  <FormControl>
                    <FormLabel>Other</FormLabel>
                    <Input
                      name="other"
                      value={formData.other}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Generate Certificate
              </Button>
              <Button variant="ghost" colorScheme="red" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
