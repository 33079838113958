import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { RecordRequestTransition } from "app/api/type";
import React, { useEffect } from "react";
import {
  FaArrowRight,
  FaCheck,
  FaCheckDouble,
  FaMinusCircle,
  FaQuestionCircle,
  FaRedo,
  FaTimesCircle
} from "react-icons/fa";
import { useUpdateRecordRequestStatusMutation } from "../api";
import { RecordRequestStatusTag } from "./record-request-status.tag";

interface Props {
  recordRequestId: string;
  recordRequestTransition: RecordRequestTransition;
}

export const UpdateRecordRequestStatusModal: React.FC<Props> = (
  props: Props
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [updateRecordRequestStatus, updateRecordRequestStatusResult] =
    useUpdateRecordRequestStatusMutation();

  useEffect(() => {
    if (updateRecordRequestStatusResult.isSuccess) {
      toast({
        title: "Success",
        description: "Request status updated successfully",
        status: "success",
        position: "top",
        duration: 3000,
        isClosable: true
      });

      onClose();
    } else if (updateRecordRequestStatusResult.isError) {
      toast({
        title: "Error",
        description: "Request status updated failed",
        status: "error",
        position: "top",
        duration: 3000,
        isClosable: true
      });
    }
  }, [updateRecordRequestStatusResult]);

  return (
    <>
      {props.recordRequestTransition.from === "Initialized" &&
        props.recordRequestTransition.to === "Pending" && (
          <Tooltip hasArrow label="Send to admin for approval">
            <Button
              colorScheme={"orange"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaQuestionCircle />}
            >
              Request Approval
            </Button>
          </Tooltip>
        )}

      {props.recordRequestTransition.from === "Pending" &&
        props.recordRequestTransition.to === "Approved" && (
          <Tooltip
            hasArrow
            label="Approve the record request. The report can now be printed."
          >
            <Button
              colorScheme={"green"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaCheck />}
            >
              Approve
            </Button>
          </Tooltip>
        )}

      {props.recordRequestTransition.from === "Approved" &&
        props.recordRequestTransition.to === "Fulfilled" && (
          <Tooltip
            hasArrow
            label="Mark the record request as complete. Ensure that you have printed and sent the report to the requester."
          >
            <Button
              colorScheme={"green"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaCheckDouble />}
            >
              Mark as Fulfilled
            </Button>
          </Tooltip>
        )}

      {props.recordRequestTransition.from === "Initialized" &&
        props.recordRequestTransition.to === "Missing" && (
          <Tooltip hasArrow label="Mark the record request as missing.">
            <Button
              colorScheme={"red"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaMinusCircle />}
            >
              Mark as Missing
            </Button>
          </Tooltip>
        )}

      {props.recordRequestTransition.from === "Pending" &&
        props.recordRequestTransition.to === "Rejected" && (
          <Tooltip hasArrow label="Mark the record request as rejected.">
            <Button
              colorScheme={"red"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaTimesCircle />}
            >
              Reject Request
            </Button>
          </Tooltip>
        )}

      {props.recordRequestTransition.from === "Pending" &&
        props.recordRequestTransition.to === "Initialized" && (
          <Tooltip
            hasArrow
            label="Ask the department to review this record request for further changes."
          >
            <Button
              colorScheme={"orange"}
              size={{ base: "xs", lg: "sm" }}
              onClick={onOpen}
              leftIcon={<FaRedo />}
            >
              Request Changes
            </Button>
          </Tooltip>
        )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "xs", md: "md" }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={{ base: "md", lg: "lg" }}>
            Request Status Update
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <Text fontSize={{ base: "sm", lg: "md" }}>
                Are you sure you want to proceed?
              </Text>

              <Stack direction="row" spacing={1} alignItems="center">
                <RecordRequestStatusTag
                  recordRequestStatusName={props.recordRequestTransition.from}
                />
                <FaArrowRight />
                <RecordRequestStatusTag
                  recordRequestStatusName={props.recordRequestTransition.to}
                />
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
              size={{ base: "xs", lg: "sm" }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              size={{ base: "xs", lg: "sm" }}
              onClick={() => {
                updateRecordRequestStatus({
                  data: {
                    recordRequest: {
                      id: props.recordRequestId,
                      recordRequestStatusName: props.recordRequestTransition.to
                    }
                  }
                });
              }}
              isLoading={updateRecordRequestStatusResult.isLoading}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
