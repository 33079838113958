import { api } from "app/api";
import * as ApiTypes from "./type";

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<
      ApiTypes.CreateUserResponse,
      ApiTypes.CreateUserRequest
    >({
      query: (data) => ({
        url: `/user/create`,
        method: `POST`,
        body: data
      })
    }),

    getUserList: builder.query<
      ApiTypes.GetUserListResponse,
      ApiTypes.GetUserListRequest
    >({
      query: () => ({
        url: `/users/list`,
        method: `GET`
      })
    }),

    getAllDepartments: builder.query<
      ApiTypes.GetAllDepartmentsResponse,
      ApiTypes.GetAllDepartmentsRequest
    >({
      query: () => ({
        url: `/departments/get`,
        method: `GET`
      })
    }),

    getUserDetails: builder.query<
      ApiTypes.GetUserDetailsResponse,
      ApiTypes.GetUserDetailsRequest
    >({
      query: ({ id }) => ({
        url: `/user/details/get`,
        method: `GET`,
        params: { id }
      })
    })
  }),
  overrideExisting: false
});

export const {
  useCreateUserMutation,
  useGetUserListQuery,
  useGetUserDetailsQuery,
  useGetAllDepartmentsQuery
} = userApi;
