import React, { useEffect, useState } from "react";
import {
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Center,
  Spinner,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  StackDivider
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  useAddPatientDocumentToPoliceCaseMutation,
  useGetAllDocumentTypesQuery,
  useLazyGetDocumentsFromCaseQuery,
  useLazyGetInjuryCertificatesOfPatientPoliceCaseQuery,
  useLazyGetPoliceCaseDetailsQuery
} from "../api";
import { Gender, DocumentType as PoliceCaseDocumentType } from "app/api/type";
import { PatientDetailComponent } from "./patient-detail.component";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";
import { FormFieldComponent } from "modules/dynamic-form/components/form-field.componenet";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { InformationCardComponent } from "./information-card.component";
import InjuryCertificateModal from "./injury-certificate.component";
import { InjuryCertificatePrintComponent } from "./injury-certificate-print.component";
import { DischargeCertificateModalComponent } from "./discharge-certificate-modal.component";
import { PrintableDischargeCertificateComponent } from "./printable-discharge-certificate.component";

interface Props {
  policeCaseId: string;
  subjectIndex: number;
}

export const PatientDocumentComponent: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();

  const [getPoliceCaseDetails, getPoliceCaseDetailsResult] =
    useLazyGetPoliceCaseDetailsQuery();

  const getAllDocumentTypesResult = useGetAllDocumentTypesQuery({});

  const [documentTypes, setDocumentTypes] = useState<PoliceCaseDocumentType[]>(
    []
  );

  const [getExistingInjuryCertificate, getExistingInjuryCertificateResult] =
    useLazyGetInjuryCertificatesOfPatientPoliceCaseQuery();

  useEffect(() => {
    if (getPoliceCaseDetailsResult.data) {
      getExistingInjuryCertificate({
        patientId:
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].patient.id,
        policeCaseId: props.policeCaseId
      });
    }
  }, [
    props.subjectIndex,
    getPoliceCaseDetailsResult.data,
    props.policeCaseId,
    getExistingInjuryCertificateResult.data
  ]);

  const [tabIndex, setTabIndex] = useState(0);
  const [getDocumentsFromCase, getDocumentsFromCaseResult] =
    useLazyGetDocumentsFromCaseQuery();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    if (getAllDocumentTypesResult.data && getPoliceCaseDetailsResult.data) {
      console.log(
        "Calling api with ",
        getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
          props.subjectIndex
        ].patient.id
      );
      getDocumentsFromCase({
        documentTypeName:
          getAllDocumentTypesResult.data.documentTypes[tabIndex].name,
        patientId:
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].patient.id,
        policeCaseId: props.policeCaseId
      });
    }
  }, [
    tabIndex,
    getAllDocumentTypesResult.data,
    getPoliceCaseDetailsResult.data
  ]);

  useEffect(() => {
    getPoliceCaseDetails({ id: props.policeCaseId });
  }, [props.policeCaseId]);

  const [addDocument, addDocumentResult] =
    useAddPatientDocumentToPoliceCaseMutation();

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = () => {
    setIsLoading(true);
    const targetDocumentType =
      getAllDocumentTypesResult.data?.documentTypes.find(
        (documentType) => documentType.name === documentTypes[tabIndex].name
      );

    const documentAttributes = Object.entries(form).map(([key, value]) => {
      const targetDocumentFormField =
        targetDocumentType?.documentFormFields.find(
          (documentFormField) => documentFormField.fieldLabelName === key
        );

      const targetCode = targetDocumentFormField?.fieldValues?.find(
        (fieldValue) => fieldValue.value === value
      )?.code;

      return {
        fieldLabelName: key,
        fieldTypeName: targetDocumentFormField?.fieldTypeName as string,
        code: targetCode ? targetCode : null,
        value: value
      };
    });

    const data = {
      documentTypeName: targetDocumentType?.name as string,
      patientId: getPoliceCaseDetailsResult.data?.policeCaseWithDetails
        .subjects[props.subjectIndex].patient.id as string,
      policeCaseId: props.policeCaseId,
      documentAttributes
    };
    console.log("Here's data: ", { data });
    addDocument({ data });
  };

  useEffect(() => {
    if (addDocumentResult.isSuccess) {
      setIsLoading(false);
      setForm({});
    }
  }, [addDocumentResult.isSuccess]);

  useEffect(() => {
    if (getAllDocumentTypesResult.isSuccess) {
      const locationCategory = window.localStorage.getItem(
        "locationCategory"
      ) as string;

      if (locationCategory === "Counter") {
        setDocumentTypes([]);
      } else if (locationCategory === "Ward") {
        setDocumentTypes(
          [
            "Injury Note",
            "Treatment Sheet",
            "Diagnostic Report",
            "Transfer Note",
            "Operation Note",
            "Investigation Report",
            "Discharge Certificate",
            "Medical Certificate"
          ].map((name) => {
            return {
              name: name
            };
          })
        );
      } else if (locationCategory === "Medical Certification") {
        setDocumentTypes(
          [
            "Injury Note",
            "Treatment Sheet",
            "Diagnostic Report",
            "Transfer Note",
            "Operation Note",
            "Investigation Report",
            "Discharge Certificate",
            "Death Certificate",
            "Medical Certificate"
          ].map((name) => {
            return {
              name: name
            };
          })
        );
      }
    }
  }, [getAllDocumentTypesResult.data]);

  const [isLargeScreen] = useMediaQuery("(min-width: 768px)");

  const [form, setForm] = useState<{ [key: string]: string }>({});

  return getPoliceCaseDetailsResult.data ? (
    <Stack spacing="4">
      <Stack direction="row" justify={"space-between"} align={"center"}>
        <Text
          color="gray.500"
          fontSize={{ base: "sm", md: "md", lg: "md", xl: "lg" }}
          fontWeight="bold"
        >
          Case{" "}
          {getPoliceCaseDetailsResult.data.policeCaseWithDetails.policeCase.id}
        </Text>

        <Button
          size={{ base: "xs", lg: "sm" }}
          onClick={() => {
            const locationCategory = window.localStorage.getItem(
              "locationCategory"
            ) as string;
            navigate(
              `/${getDashboardName(locationCategory)}/case/${
                props.policeCaseId
              }/details`
            );
          }}
        >
          Case Details
        </Button>
      </Stack>
      <PatientDetailComponent
        patientName={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.name
        }
        patientId={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].patient.id
        }
        patientNationalIdNumber={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.nationalIdNumber
        }
        patientContactNumber={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.contactNumber
        }
        dateOfBirth={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.dateOfBirth
        }
        gender={
          getPoliceCaseDetailsResult.data.policeCaseWithDetails.subjects[
            props.subjectIndex
          ].person.gender as Gender
        }
      />

      <Tabs
        variant="soft-rounded"
        colorScheme="blue"
        index={tabIndex}
        onChange={handleTabsChange}
      >
        {isLargeScreen ? (
          <TabList>
            {documentTypes.map((documentType) => {
              return (
                <Tab fontSize={{ md: "xs", lg: "sm" }}>{documentType.name}</Tab>
              );
            })}
          </TabList>
        ) : (
          <Menu size="sm">
            <Center>
              <MenuButton
                size={{ base: "xs", lg: "sm" }}
                width="100%"
                as={Button}
                variant="outline"
                rightIcon={<FaChevronDown />}
              >
                Select Document Type ({documentTypes[tabIndex].name})
              </MenuButton>
            </Center>
            <MenuList>
              {documentTypes.map((documentType, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleTabsChange(index);
                    }}
                    isDisabled={index === tabIndex}
                    _disabled={{
                      bgColor: "blue.100",
                      color: "blue.700"
                    }}
                  >
                    {documentType.name}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}

        <TabPanels>
          {documentTypes.map((documentType) => {
            const filteredDocumentType =
              getAllDocumentTypesResult.data?.documentTypes.find(
                (documentTypeFromReq) =>
                  documentTypeFromReq.name === documentType.name
              );

            return (
              <TabPanel key={documentType.name}>
                {filteredDocumentType && (
                  <Stack
                    direction="row"
                    divider={<StackDivider />}
                    height={"60vh"}
                  >
                    <Stack
                      width="75%"
                      direction="column"
                      overflowY={"scroll"}
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "6px"
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                          backgroundColor: "gray.100"
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "gray.400",
                          borderRadius: "12px"
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "gray.100"
                        }
                      }}
                    >
                      {getDocumentsFromCaseResult.data &&
                      getPoliceCaseDetailsResult.data &&
                      getAllDocumentTypesResult.data &&
                      filteredDocumentType.name === "Discharge Certificate" ? (
                        <>
                          <DischargeCertificateModalComponent
                            person={
                              getPoliceCaseDetailsResult.data
                                .policeCaseWithDetails.subjects[
                                props.subjectIndex
                              ].person
                            }
                            policeCaseWithDetails={
                              getPoliceCaseDetailsResult.data
                                .policeCaseWithDetails
                            }
                            patient={
                              getPoliceCaseDetailsResult.data
                                .policeCaseWithDetails.subjects[
                                props.subjectIndex
                              ].patient
                            }
                            isDisable={
                              getDocumentsFromCaseResult.data
                                .documentsWithAttributes.length > 0
                                ? true
                                : false
                            }
                          />

                          {(
                            getDocumentsFromCaseResult.data &&
                            getDocumentsFromCaseResult.data
                              .documentsWithAttributes
                          ).map((document) => {
                            return (
                              <PrintableDischargeCertificateComponent
                                document={document}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <PageCardComponent>
                          {filteredDocumentType.documentFormFields.map(
                            (documentFormField, index) => {
                              const propsForFormField = {
                                key: index,
                                fieldTypeName: documentFormField.fieldTypeName,
                                fieldLabelName:
                                  documentFormField.fieldLabelName,
                                form: form,
                                setForm: setForm,
                                fieldValues:
                                  documentFormField.fieldValues || [],
                                patientId: getPoliceCaseDetailsResult.data
                                  ?.policeCaseWithDetails.subjects[
                                  props.subjectIndex
                                ].patient.id as string,
                                policeCaseId: props.policeCaseId,
                                documentTypeName: documentType.name
                              };
                              return (
                                <FormFieldComponent {...propsForFormField} />
                              );
                            }
                          )}
                          <Button
                            width={"100%"}
                            isLoading={isLoading}
                            isDisabled={
                              !(
                                filteredDocumentType.documentFormFields.length >
                                0
                              )
                            }
                            onClick={() => {
                              console.log(form);
                              handleOnSubmit();
                            }}
                          >
                            Submit Information
                          </Button>
                        </PageCardComponent>
                      )}
                    </Stack>

                    <Stack
                      width="25%"
                      direction="column"
                      overflowY={"scroll"}
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "6px"
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                          backgroundColor: "gray.100"
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "gray.400",
                          borderRadius: "12px"
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "gray.100"
                        }
                      }}
                    >
                      {getDocumentsFromCaseResult.data &&
                        getPoliceCaseDetailsResult.data &&
                        getAllDocumentTypesResult.data &&
                        getAllDocumentTypesResult.data.documentTypes[tabIndex]
                          .name === "Injury Note" && (
                          <Stack>
                            {getExistingInjuryCertificateResult.data &&
                            getExistingInjuryCertificateResult.data
                              .certificates &&
                            getExistingInjuryCertificateResult.data.certificates
                              .length < 3 ? (
                              <InjuryCertificateModal
                                data={getDocumentsFromCaseResult.data}
                                person={
                                  getPoliceCaseDetailsResult.data
                                    .policeCaseWithDetails.subjects[
                                    props.subjectIndex
                                  ].person
                                }
                                policeCaseWithDetails={
                                  getPoliceCaseDetailsResult.data
                                    .policeCaseWithDetails
                                }
                                patient={
                                  getPoliceCaseDetailsResult.data
                                    .policeCaseWithDetails.subjects[
                                    props.subjectIndex
                                  ].patient
                                }
                              />
                            ) : (
                              <Button
                                size={"2xl"}
                                padding={2}
                                colorScheme="red"
                                isDisabled={true}
                              >
                                Can't Generate more than 3 certificates
                              </Button>
                            )}
                            {getExistingInjuryCertificateResult.data &&
                              getExistingInjuryCertificateResult.data
                                .certificates.length > 0 &&
                              getExistingInjuryCertificateResult.data.certificates.map(
                                (certificate) => {
                                  return (
                                    <InjuryCertificatePrintComponent
                                      data={certificate}
                                    />
                                  );
                                }
                              )}
                          </Stack>
                        )}
                      {(getDocumentsFromCaseResult.data
                        ? getDocumentsFromCaseResult.data
                            .documentsWithAttributes
                        : []
                      ).map((document) => {
                        return <InformationCardComponent document={document} />;
                      })}
                    </Stack>
                  </Stack>
                )}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Stack>
  ) : (
    <Center width="100%" height="256px">
      <Spinner />
    </Center>
  );
};
