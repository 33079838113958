import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  InputGroup,
  Stack,
  Text,
  Center,
  Spinner
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useLazyGetRecordRequestQuery } from "../api";
import {
  PoliceCaseWithDetails,
  RecordRequest,
  RecordRequestStatusName,
  RecordRequestTransition
} from "app/api/type";
import { ExistingPoliceCaseTableComponent } from "../components/existing-police-case-table.component";
import { useLazyUseSearchPoliceCasesQuery } from "modules/police-case/api";
import { ConnectPoliceCaseTableComponent } from "../components/connect-police-case-table.component";
import { RecordRequestStatusTag } from "../components/record-request-status.tag";
import { UpdateRecordRequestStatusModal } from "../components/update-record-request-status.modal";
import { PrintableCaseReportComponent } from "modules/police-case/components/printable-case-report.component";
import { PrintableCaseDocumentComponent } from "../pdf/printable-case-document.component";

interface Props {}

export const ConnectRecordRequestPage: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const [getRequestRecord, getRequestRecordResult] =
    useLazyGetRecordRequestQuery();

  const [searchCase, searchCaseResult] = useLazyUseSearchPoliceCasesQuery();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const handleSearch = () => {
    searchCase({
      searchQuery
    });
  };

  useEffect(() => {
    searchCase({
      searchQuery: ""
    });
  }, []);

  const [recordRequest, setRecordRequest] = useState<RecordRequest>();
  const [policeCasesWithDetails, setPoliceCasesWithDetails] = useState<
    PoliceCaseWithDetails[]
  >([]);

  useEffect(() => {
    if (id) {
      getRequestRecord({ id });
    }
  }, []);

  useEffect(() => {
    if (getRequestRecordResult.isSuccess) {
      setRecordRequest(
        getRequestRecordResult.data.recordRequestWithCases.recordRequest
      );
      setPoliceCasesWithDetails(
        getRequestRecordResult.data.recordRequestWithCases
          .policeCasesWithDetails
      );
    }
  }, [getRequestRecordResult]);

  const isAdmin =
    (window.localStorage.getItem("locationCategory") as string) === "Admin";

  return (
    <Stack gap="2">
      {getRequestRecordResult.data ? (
        <PageCardComponent>
          <Stack direction="row" justifyContent={"space-between"}>
            <Stack width="80%" alignItems="flex-start">
              <RecordRequestStatusTag
                recordRequestStatusName={
                  getRequestRecordResult.data.recordRequestWithCases
                    .recordRequest
                    .recordRequestStatusName as RecordRequestStatusName
                }
              />
              <Text fontSize={{ base: "sm", lg: "md" }} color="gray.600">
                Request Source :{" "}
                {
                  getRequestRecordResult.data.recordRequestWithCases
                    .recordRequest.requestSource
                }
              </Text>
              <Text fontSize={{ base: "sm", lg: "md" }} color="gray.600">
                Record Reference :{" "}
              </Text>
              <Stack
                p={4}
                borderStyle="dashed"
                borderWidth={1}
                borderColor="gray.200"
              >
                <Text fontSize={{ base: "sm", lg: "md" }}>
                  {
                    getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.requestDocumentText
                  }
                </Text>
              </Stack>
            </Stack>

            <Stack alignItems={"flex-end"}>
              <UpdateRecordRequestStatusModal
                recordRequestId={
                  getRequestRecordResult.data.recordRequestWithCases
                    .recordRequest.id
                }
                recordRequestTransition={
                  {
                    from: getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.recordRequestStatusName,

                    to: "Initialized"
                  } as RecordRequestTransition
                }
              />

              <UpdateRecordRequestStatusModal
                recordRequestId={
                  getRequestRecordResult.data.recordRequestWithCases
                    .recordRequest.id
                }
                recordRequestTransition={
                  {
                    from: getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.recordRequestStatusName,

                    to: "Pending"
                  } as RecordRequestTransition
                }
              />

              {isAdmin && (
                <UpdateRecordRequestStatusModal
                  recordRequestId={
                    getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.id
                  }
                  recordRequestTransition={
                    {
                      from: getRequestRecordResult.data.recordRequestWithCases
                        .recordRequest.recordRequestStatusName,

                      to: "Approved"
                    } as RecordRequestTransition
                  }
                />
              )}

              {!isAdmin && (
                <UpdateRecordRequestStatusModal
                  recordRequestId={
                    getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.id
                  }
                  recordRequestTransition={
                    {
                      from: getRequestRecordResult.data.recordRequestWithCases
                        .recordRequest.recordRequestStatusName,

                      to: "Fulfilled"
                    } as RecordRequestTransition
                  }
                />
              )}

              <UpdateRecordRequestStatusModal
                recordRequestId={
                  getRequestRecordResult.data.recordRequestWithCases
                    .recordRequest.id
                }
                recordRequestTransition={
                  {
                    from: getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.recordRequestStatusName,

                    to: "Missing"
                  } as RecordRequestTransition
                }
              />

              {isAdmin && (
                <UpdateRecordRequestStatusModal
                  recordRequestId={
                    getRequestRecordResult.data.recordRequestWithCases
                      .recordRequest.id
                  }
                  recordRequestTransition={
                    {
                      from: getRequestRecordResult.data.recordRequestWithCases
                        .recordRequest.recordRequestStatusName,

                      to: "Rejected"
                    } as RecordRequestTransition
                  }
                />
              )}
            </Stack>
          </Stack>
        </PageCardComponent>
      ) : (
        <Center width="100%" height="20vh">
          <Spinner />
        </Center>
      )}

      {getRequestRecordResult.data && (
        <Stack>
          {isAdmin ? (
            <Stack direction={{ base: "column", md: "row" }} width="100%">
              <PrintableCaseDocumentComponent
                data={getRequestRecordResult.data}
              />

              {getRequestRecordResult.data.recordRequestWithCases
                .policeCasesWithDetails.length > 0 && (
                <PrintableCaseReportComponent
                  policeCaseId={
                    getRequestRecordResult.data.recordRequestWithCases
                      .policeCasesWithDetails[0].policeCase.id
                  }
                  record={getRequestRecordResult.data}
                />
              )}
            </Stack>
          ) : (
            <Stack direction={{ base: "column", md: "row" }} width="100%">
              {getRequestRecordResult.data.recordRequestWithCases.recordRequest
                .recordRequestStatusName === ("Initialized" || "Pending") && (
                <PrintableCaseDocumentComponent
                  data={getRequestRecordResult.data}
                />
              )}
              {getRequestRecordResult.data.recordRequestWithCases
                .policeCasesWithDetails.length > 0 &&
                getRequestRecordResult.data?.recordRequestWithCases
                  .recordRequest.recordRequestStatusName ===
                  ("Approved" || "Fulfilled") && (
                  <PrintableCaseReportComponent
                    policeCaseId={
                      getRequestRecordResult.data.recordRequestWithCases
                        .policeCasesWithDetails[0].policeCase.id
                    }
                    record={getRequestRecordResult.data}
                  />
                )}
            </Stack>
          )}
        </Stack>
      )}

      {getRequestRecordResult.data ? (
        <PageCardComponent>
          <Text fontSize={{ base: "sm", lg: "md" }} fontWeight="semibold">
            Connected Cases
          </Text>
          <ExistingPoliceCaseTableComponent
            requestRecord={
              getRequestRecordResult.data.recordRequestWithCases.recordRequest
            }
            policeCasesWithDetails={policeCasesWithDetails}
          />
        </PageCardComponent>
      ) : (
        <Center width="100%" height="30vh">
          <Spinner />
        </Center>
      )}

      <Divider />

      {!isAdmin &&
        getRequestRecordResult.data?.recordRequestWithCases.recordRequest
          .recordRequestStatusName === "Initialized" && (
          <Stack>
            <PageCardComponent>
              <Text fontSize={{ base: "sm", lg: "md" }} fontWeight="bold">
                Search Query
              </Text>
              <Stack direction={{ base: "column", lg: "row" }}>
                <InputGroup size="sm">
                  <Input
                    size={{ base: "xs", md: "xs", lg: "sm" }}
                    value={searchQuery}
                    placeholder="Search with Document ID / Patient Name / Contact / Admission Department / NID"
                    onChange={(event) => {
                      setSearchQuery(event.target.value);
                    }}
                  />

                  <Button
                    size={{ base: "xs", lg: "sm" }}
                    leftIcon={<FaSearch />}
                    colorScheme={"blue"}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </InputGroup>
              </Stack>
            </PageCardComponent>

            <PageCardComponent>
              {searchCaseResult.isSuccess && id && (
                <ConnectPoliceCaseTableComponent
                  requestId={id}
                  policeCasesWithDetails={
                    searchCaseResult.data.policeCasesWithDetails
                  }
                />
              )}
            </PageCardComponent>
          </Stack>
        )}
    </Stack>
  );
};
