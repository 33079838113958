import React from "react";
import dayjs from "dayjs";
import {
  Text,
  StyleSheet,
  Document,
  Font,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { env } from "app/config";
import { GetPoliceCaseReportResponse } from "../api/type";
import { GetRecordRequestByIdResponse } from "modules/record-request/api/type";

interface PrintableCaseReportPageProps {
  data: GetPoliceCaseReportResponse;
  record: GetRecordRequestByIdResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  fonts: [
    {
      src: "/fonts/NotoSerifBengali-Regular.ttf"
    },
    {
      src: "/fonts/NotoSerif-SemiBold.ttf",
      fontWeight: "semibold"
    },
    {
      src: "/fonts/NotoSerifBengali-Bold.ttf",
      fontWeight: "bold"
    }
  ]
});

const styles = StyleSheet.create({
  body: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali"
  },
  // title page
  logo: {
    width: "70px",
    marginTop: 30,
    objectFit: "contain",
    alignSelf: "center"
  },
  hospitalName: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center"
  },
  hospitalInformation: {
    fontSize: 11,
    fontWeight: "semibold",
    textAlign: "center"
  },
  title: {
    fontSize: 28,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 150,
    marginBottom: 5
  },
  subTitle: {
    fontSize: 14,
    fontWeight: "semibold"
  },
  titleHeaderTable: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto",
    border: "1px solid #666666"
  },
  titleHeaderTableDetails: {
    margin: 10,
    alignSelf: "center"
  },
  titleCaseTable: {
    fontSize: 11,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "70%",
    margin: "auto"
  },
  titleCaseTableDetails: {
    margin: 10,
    alignSelf: "auto"
  },
  // police case page
  policeCaseText: {
    fontSize: 11,
    fontWeight: "semibold",
    textAlign: "center",
    padding: 5,
    marginBottom: 10,
    border: "1px solid #666666"
  },
  policeCaseInfo: {
    fontSize: 11,
    marginBottom: 10
  },
  policeCaseNote: {
    fontSize: 11,
    marginTop: 10
  },
  policeCaseSignature: {
    position: "absolute",
    fontSize: 11,
    bottom: "-1.50in",
    left: "0.75in",
    right: "0.75in",
    borderBottom: "1px solid #000"
  },

  // case information
  caseInformationTable: {
    fontSize: 11,
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  caseInformationTableDetails: {
    marginBottom: 20,
    width: "70%"
  },
  caseHeader: {
    width: "100%"
  },
  caseTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  caseDetails: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  highlight: {
    fontWeight: "semibold"
  },

  // patient page
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  headerTable: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 11,
    marginBottom: 20,
    border: "1px solid #666666",
    borderRadius: 5
  },
  headerDetails: {
    padding: 10
  },

  // document page
  documentImage: {
    width: "400px",
    objectFit: "contain",
    alignSelf: "center",
    marginVertical: "10"
  },
  documentImgFileName: {
    fontSize: 11,
    color: "#666666",
    alignSelf: "center",
    marginBottom: 10
  },
  remarkContainer: {
    width: "50%",
    margin: "0 auto",
    padding: "8px",
    border: "2px solid #b2b2b2",
    borderRadius: "4px",
    backgroundColor: "#f0f0f0"
  },
  remark: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "4px",
    borderRadius: "2px"
  },
  remarkImg: {
    width: "20px"
  },
  remarkText: {
    fontSize: 11
  },
  injuryNotes: {
    marginTop: 10,
    border: "1px solid #dfdfdf",
    borderRadius: 5
  },
  injuryTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  injuryInfo: {
    width: "50%",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 8,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  },
  documentFileName: {
    fontSize: 11,
    fontWeight: "semibold",
    color: "#000",
    marginVertical: 10
  }
});

export const PrintableCaseReportPage: React.FC<PrintableCaseReportPageProps> = (
  props: PrintableCaseReportPageProps
) => {
  console.log("ppp", props.data);
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View
            style={{
              borderTop: "1px solid #000",
              marginBottom: 5
            }}
          ></View>
          <Text
            style={{
              fontSize: 8,
              color: "#666666",
              alignSelf: "center",
              marginBottom: 5
            }}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
            fixed
          />
        </View>

        {/* title page */}
        <View>
          <View>
            <Image style={styles.logo} src={env.hospitalLogo} />
            <Text style={styles.hospitalName}>{env.hospitalName}</Text>
            <Text style={styles.hospitalInformation}>
              Address: {env.hospitalAddress}
            </Text>
            <Text style={styles.hospitalInformation}>
              Contact: {env.hospitalContact}
            </Text>
          </View>

          <View>
            <Text style={styles.title}>Case Record File</Text>
          </View>

          <View>
            <View style={[styles.titleHeaderTable]}>
              <View style={styles.titleHeaderTableDetails}>
                <Text>Document ID</Text>
                <Text>Police Reference</Text>
                <Text>Hospital Reference</Text>
              </View>
              <View style={styles.titleHeaderTableDetails}>
                <Text>
                  {props.data.policeCaseWithReport.policeCase.id || "-"}
                </Text>
                <Text>
                  {props.data.policeCaseWithReport.policeCase.policeReference ||
                    "-"}
                </Text>
                <Text>
                  {props.data.policeCaseWithReport.policeCase
                    .hospitalReference || "-"}
                </Text>
              </View>
            </View>

            <View style={styles.titleCaseTable}>
              <View style={styles.titleCaseTableDetails}>
                <Text>Request ID</Text>
                <Text>Requesting Entity</Text>
                <Text>Requesting Date</Text>
                <Text>Fulfillment Date</Text>
              </View>
              <View style={styles.titleCaseTableDetails}>
                <Text>
                  {props.record.recordRequestWithCases.recordRequest.id || "-"}
                </Text>
                <Text>
                  {props.record.recordRequestWithCases.recordRequest
                    .requestSource || "-"}
                </Text>
                <Text>
                  {dayjs(
                    props.record.recordRequestWithCases.recordRequest
                      .requestedAt
                  ).format("DD MMM, YYYY") || "_"}
                </Text>
                <Text>
                  {dayjs(
                    props.record.recordRequestWithCases.recordRequest.deadline
                  ).format("DD MMM, YYYY") || "_"}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* police case page */}
        <View style={styles.caseHeader} break>
          <Text style={styles.policeCaseText}>POLICE CASE</Text>
          <Text style={[styles.policeCaseInfo, { fontWeight: "semibold" }]}>
            Case No : {props.data.policeCaseWithReport.policeCase.id}
          </Text>
          <Text style={styles.policeCaseInfo}>
            Report on the case of injury or of poisoning admitted into the{" "}
            {env.hospitalName}.
          </Text>
          {props.data.policeCaseWithReport.subjects.map((row, i) => (
            <View key={i}>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Name :</Text>
                <Text style={styles.caseDetails}>{row.person.name || "_"}</Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>
                  Father's/Husband's name :
                </Text>
                <Text style={styles.caseDetails}>
                  {row.person.fatherName || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Mother's name :</Text>
                <Text style={styles.caseDetails}>{"_"}</Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Sex :</Text>
                <Text style={styles.caseDetails}>
                  {row.person.gender || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Caste :</Text>
                <Text style={styles.caseDetails}>
                  {row.person.religionName || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Age :</Text>
                <Text style={styles.caseDetails}>
                  {dayjs(row.person.dateOfBirth).format("DD MMM, YYYY") || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Occupation :</Text>
                <Text style={styles.caseDetails}>{"_"}</Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>Last residence:</Text>
                <Text style={styles.caseDetails}>{"_"}</Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>From where received :</Text>
                <Text style={styles.caseDetails}>
                  {row.patientCaseProfile.patientCategoryName || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={styles.caseDetails}>
                  NB : (if case sent up by the police it should be noted under
                  this head the name of the police station form where it is
                  sent) :
                </Text>
                <Text style={styles.caseDetails}>
                  {row.patientCaseProfile.policeCaseId || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={[styles.caseDetails, styles.highlight]}>
                  Date of admission into the hospital :
                </Text>
                <Text style={styles.caseDetails}>
                  {dayjs(row.patientCaseProfile.admissionDate).format(
                    "DD MMM, YYYY"
                  ) || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={[styles.caseDetails, styles.highlight]}>
                  Cause of injury or of posisoning as stated by the patient :
                </Text>
                <Text style={styles.caseDetails}>
                  {row.patientCaseProfile.disease || "_"}
                </Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={[styles.caseDetails, styles.highlight]}>
                  Result of examination by the medical officer :
                </Text>
                <Text style={styles.caseDetails}>{"_"}</Text>
              </View>
              <View style={styles.caseTable}>
                <Text style={[styles.caseDetails, styles.highlight]}>
                  Cause and symptoms :
                </Text>
                <Text style={styles.caseDetails}>
                  {row.patientCaseProfile.disease || "_"}
                </Text>
              </View>
            </View>
          ))}
          <Text style={styles.policeCaseNote}>
            NB: If case of injury, the columns in the reverse should also be
            filled up by the examining medical officer with such remarks as
            fe/she may consider necessary to show clearly his/her opinion.
          </Text>
          <Text style={styles.policeCaseSignature}>
            Signature, Name, Code no, Mobile no & Seal of the medical officer
          </Text>
        </View>

        {/* patient page */}
        <View break>
          <View style={styles.headerContent}>
            {props.data.policeCaseWithReport.subjects.map((row, i) => (
              <>
                <View wrap={false}>
                  {/* page header */}
                  <View style={styles.header}>
                    <View style={styles.headerTable}>
                      <View style={styles.headerDetails}>
                        <Text>Document ID</Text>
                        <Text>Police Reference</Text>
                        <Text>Hospital Reference</Text>
                      </View>
                      <View style={styles.headerDetails}>
                        <Text>
                          {props.data.policeCaseWithReport.policeCase.id || "-"}
                        </Text>
                        <Text>
                          {props.data.policeCaseWithReport.policeCase
                            .policeReference || "-"}
                        </Text>
                        <Text>
                          {props.data.policeCaseWithReport.policeCase
                            .hospitalReference || "-"}
                        </Text>
                      </View>
                    </View>

                    <View style={styles.headerTable}>
                      <View style={styles.headerDetails}>
                        <Text>Patient ID</Text>
                        <Text>Ticket ID</Text>
                        <Text>Hospital Reference </Text>
                      </View>
                      <View style={styles.headerDetails} key={i}>
                        <Text>{row.patient.id || "-"}</Text>
                        <Text>{row.patientCaseProfile.ticketId || "-"}</Text>
                        <Text>
                          {props.data.policeCaseWithReport.policeCase
                            .hospitalReference || "-"}
                        </Text>
                      </View>
                    </View>
                  </View>

                  {/* personal information */}
                  <View>
                    <Text style={styles.subTitle}>Personal Information :</Text>
                    <View style={styles.caseInformationTable}>
                      <View style={styles.caseInformationTableDetails}>
                        <Text>Name</Text>
                        <Text>Contact Number</Text>
                        <Text>Date of Birth</Text>
                        <Text>Gender</Text>
                        <Text>Fathers Name</Text>
                        <Text>Spouse Name</Text>
                        <Text>National ID Number</Text>
                        <Text>Passport Number</Text>
                      </View>

                      <View style={styles.caseInformationTableDetails} key={i}>
                        <Text>{row.person.name || "-"}</Text>
                        <Text>{row.person.contactNumber || "-"}</Text>
                        <Text>
                          {dayjs(row.person.dateOfBirth).format(
                            "DD MMM, YYYY"
                          ) || "-"}
                        </Text>
                        <Text>{row.person.gender || "-"}</Text>
                        <Text>{row.person.fatherName || "-"}</Text>
                        <Text>{row.person.spouseName || "-"}</Text>
                        <Text>{row.person.nationalIdNumber || "-"}</Text>
                        <Text>{row.person.passportNumber || "-"}</Text>
                      </View>
                    </View>
                  </View>

                  {/* patient information */}
                  <View>
                    <Text style={styles.subTitle}>Patient Information :</Text>
                    <View style={styles.caseInformationTable}>
                      <View style={styles.caseInformationTableDetails}>
                        <Text>Patient ID</Text>
                        <Text>Ticket ID</Text>
                        <Text>Document ID</Text>
                        <Text>Admission Date</Text>
                        <Text>Admission Department</Text>
                        <Text>Disease</Text>
                        <Text>Patient Category Name</Text>
                        <Text>Ward No</Text>
                      </View>

                      <View style={styles.caseInformationTableDetails} key={i}>
                        <Text>{row.patientCaseProfile.patientId || "-"}</Text>
                        <Text>{row.patientCaseProfile.ticketId || "-"}</Text>
                        <Text>
                          {row.patientCaseProfile.policeCaseId || "-"}
                        </Text>
                        <Text>
                          {dayjs(row.patientCaseProfile.admissionDate).format(
                            "DD MMM, YYYY" || "-"
                          )}
                        </Text>
                        <Text>
                          {row.patientCaseProfile.admissionDepartment || "-"}
                        </Text>
                        <Text>{row.patientCaseProfile.disease || "-"}</Text>
                        <Text>
                          {row.patientCaseProfile.patientCategoryName || "-"}
                        </Text>
                        <Text>{row.patientCaseProfile.ward || "-"}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            ))}
          </View>
        </View>
        {/* documents section */}
        <View break>
          <>
            {props.data.policeCaseWithReport.subjects.map((caseDetails) =>
              caseDetails.documentsWithAttributes.map((documents) => (
                <View>
                  <Text style={styles.documentFileName}>
                    Document Filename: {documents.name}
                  </Text>
                  {documents.documentAttributes.map((document) =>
                    document.fieldTypeName === "Image" ? (
                      <View
                        wrap={false}
                        style={[
                          {
                            border: "1px solid #dfdfdf",
                            marginBottom: "10px"
                          }
                        ]}
                      >
                        <View>
                          <View style={[styles.injuryTable]}>
                            <Text style={styles.injuryInfo}>
                              {document.fieldLabelName}
                            </Text>
                            <Image
                              style={[{ objectFit: "contain", width: "48px" }]}
                              src={document.value}
                            />
                          </View>
                        </View>
                      </View>
                    ) : (
                      <View wrap={false}>
                        <View>
                          <View style={[styles.caseTable]}>
                            <Text style={styles.caseDetails}>
                              {document.fieldLabelName}
                            </Text>
                            <Text style={styles.caseDetails}>
                              {document.value}
                            </Text>
                          </View>
                        </View>
                      </View>
                    )
                  )}{" "}
                </View>
              ))
            )}
          </>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered by Bindulogic Limited</Text>
          </View>
          <View>
            <Text>
              Printed By:{" "}
              {window.localStorage.getItem("locationName") || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
