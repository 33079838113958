import { api } from "app/api";
import * as ApiTypes from "./type";

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ApiTypes.LoginResponse, ApiTypes.LoginRequest>({
      query: ({ data }) => ({
        url: `/login`,
        method: `POST`,
        body: { data }
      })
    }),

    getAllLocations: builder.query<
      ApiTypes.GetAllLocationsResponse,
      ApiTypes.GetAllLocationsRequest
    >({
      query: () => ({
        url: `/locations/get`,
        method: `GET`
      })
    })
  }),

  overrideExisting: false
});

export const { useLoginMutation, useGetAllLocationsQuery } = authApi;
