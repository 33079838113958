import { api } from "app/api";
import * as ApiTypes from "./type";

const logApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchLog: builder.query<
      ApiTypes.SearchLogResponse,
      ApiTypes.SearchLogRequest
    >({
      query: ({ searchBy, searchValue }) => ({
        url: `/log/search`,
        method: `GET`,
        params: { searchBy, searchValue }
      })
    })
  }),
  overrideExisting: false
});

export const { useSearchLogQuery, useLazySearchLogQuery } = logApi;
