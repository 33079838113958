import React from "react";
import { useGetPoliceCaseReportQuery } from "../api";
import { PrintableCaseReportPage } from "../pages/printable-case-report.page";
import { Button, Spinner, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";
import { GetRecordRequestByIdResponse } from "../../record-request/api/type";

interface PrintableCaseReportComponentProps {
  policeCaseId: string;
  record: GetRecordRequestByIdResponse;
}

export const PrintableCaseReportComponent: React.FC<
  PrintableCaseReportComponentProps
> = (props: PrintableCaseReportComponentProps) => {
  const data = useGetPoliceCaseReportQuery({
    id: props.policeCaseId
  });

  const Document = data.data ? (
    <PrintableCaseReportPage data={data.data} record={props.record} />
  ) : (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  );

  const fileName = `Case-Report-${props.policeCaseId}.pdf`;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        w={{ base: "100%", md: "50%" }}
        size={{ base: "xs", md: "sm" }}
      >
        Download Case Document
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={fileName}
      />
    </>
  );
};
