import React from "react";
import { PrintableCaseDocumentPage } from "./printable-case-document.page";
import { GetRecordRequestByIdResponse } from "../api/type";
import { Button, useDisclosure } from "@chakra-ui/react";
import { PdfPrinterModal } from "modules/core/components/pdf-printer-modal.component";

interface PrintableCaseDocumentComponentProps {
  data: GetRecordRequestByIdResponse;
}

export const PrintableCaseDocumentComponent: React.FC<
  PrintableCaseDocumentComponentProps
> = (props: PrintableCaseDocumentComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Document = <PrintableCaseDocumentPage data={props.data} />;

  const requestReport = `Record Request Document-${props.data.recordRequestWithCases.recordRequest.requestSource}.pdf`;

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        w={{ base: "100%", md: "50%" }}
        size={{ base: "xs", md: "sm" }}
      >
        Download Record Request Document
      </Button>
      <PdfPrinterModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        document={Document}
        fileName={requestReport}
      />
    </>
  );
};
