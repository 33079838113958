import React from "react";
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { UserLogTableComponent } from "../components/user-log-table.component";

interface Props {}

export const UserLogPage: React.FC<Props> = (props: Props) => {
  const { userId } = useParams();

  return (
    <Stack>
      <UserLogTableComponent userId={userId} />
    </Stack>
  );
};
