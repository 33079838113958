import React, { useEffect } from "react";
import {
  Stack,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
  Td,
  Tbody,
  Spinner,
  Center,
  Button
} from "@chakra-ui/react";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { useGetUserListQuery } from "../api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface Props {}

export const UserListTableComponent: React.FC<Props> = (props: Props) => {
  const getUserListResult = useGetUserListQuery({});
  const navigate = useNavigate();

  useEffect(() => {
    getUserListResult.refetch();
  }, []);

  return (
    <Stack spacing="6">
      <PageCardComponent>
        {getUserListResult.data ? (
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Sl.</Th>
                  <Th>Created At</Th>
                  <Th>User ID</Th>
                  <Th>National ID</Th>
                  <Th>User Name</Th>
                  <Th>Contact Number</Th>
                  <Th>Designation</Th>
                  <Th>BMDC Number</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getUserListResult.data.usersWithDetails.map(
                  (userWithDetails, index) => {
                    return (
                      <Tr key={index}>
                        <Td fontSize={{ base: "xs", md: "sm" }}>{index + 1}</Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {dayjs(userWithDetails.person.createdAt).format(
                            "DD MMM, YYYY"
                          )}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.user.id}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.person.nationalIdNumber}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.person.name}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.person.contactNumber}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.user.designationName}
                        </Td>
                        <Td fontSize={{ base: "xs", md: "sm" }}>
                          {userWithDetails.user.medicalCertificationNumber
                            ? userWithDetails.user.medicalCertificationNumber
                            : "-"}
                        </Td>
                        <Td>
                          <Button
                            size={{ base: "xs", lg: "sm" }}
                            colorScheme="blue"
                            onClick={() => {
                              const locationCategory =
                                window.localStorage.getItem(
                                  "locationCategory"
                                ) as string;
                              navigate(
                                `/${locationCategory.toLowerCase()}/user/${
                                  userWithDetails.user.id
                                }/activity-log/list`
                              );
                            }}
                          >
                            Logs
                          </Button>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Center width="100%" height="10vh">
            <Spinner />
          </Center>
        )}
      </PageCardComponent>
    </Stack>
  );
};
