export const env = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "http://localhost:1337",
  appName:
    process.env.REACT_APP_APP_NAME || "Bindulogic Enterprise Application",
  hospitalName: process.env.REACT_APP_HOSPITAL_NAME || "Bindulogic Hospital",
  hospitalLogo:
    process.env.REACT_APP_HOSPITAL_LOGO ||
    "https://i.ibb.co/vw4rzqH/bindulogic.png",
  hospitalAddress:
    process.env.REACT_APP_HOSPITAL_ADDRESS || "Mirpur, Dhaka, Bangladesh",
  hospitalContact: process.env.REACT_APP_HOSPITAL_CONTACT || "+880 1711 111 111"
};
