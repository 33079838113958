import React, { useEffect, useState } from "react";
import {
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text
} from "@chakra-ui/react";
import { Log, Person, PoliceCase, User, Patient } from "app/api/type";
import { useLazySearchLogQuery } from "../api";
import dayjs from "dayjs";
import { PageCardComponent } from "modules/core/components/page-card.component";
import { LogComponent } from "./log.component";

interface Props {
  caseId?: string;
}

export const CaseLogTableComponent: React.FC<Props> = (props: Props) => {
  const [searchLogs, searchLogsResult] = useLazySearchLogQuery();

  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [logs, setLogs] = useState<
    {
      log: Log;
      userWithDetails: {
        user: Omit<User, "password">;
        person: Person;
      };
      patientWithDetails: {
        patient: Patient;
        person: Person;
      } | null;
      policeCase: PoliceCase | null;
    }[]
  >([]);
  const [caseId, setCaseId] = useState<string>();

  useEffect(() => {
    if (props.caseId) {
      if (props.caseId === "default") {
        setIsDefault(true);
      } else {
        setIsDefault(false);
        searchLogs({ searchBy: "case", searchValue: props.caseId });
      }
    }
  }, []);

  useEffect(() => {
    if (searchLogsResult.isSuccess) {
      setLogs(searchLogsResult.data.logsWithDetails);
    }
  }, [searchLogsResult]);

  const handleSearch = () => {
    if (isDefault && caseId) {
      searchLogs({ searchBy: "case", searchValue: caseId });
    }
  };

  return (
    <Stack spacing="4">
      {isDefault ? (
        <PageCardComponent>
          <FormControl>
            <FormLabel fontSize={{ base: "xs", md: "sm", lg: "md" }}>
              Document ID
            </FormLabel>
            <Stack direction="row">
              <Input
                size={{ base: "xs", lg: "sm" }}
                value={caseId}
                onChange={(event) => {
                  setCaseId(event.target.value);
                }}
              />
              <Button
                size={{ base: "xs", lg: "sm" }}
                onClick={handleSearch}
                isLoading={searchLogsResult.isLoading}
                isDisabled={!caseId}
              >
                Search
              </Button>
            </Stack>
          </FormControl>
        </PageCardComponent>
      ) : (
        <Heading
          fontSize={{ base: "sm", md: "md", lg: "md", xl: "lg" }}
          color="gray.500"
        >
          ID : {props.caseId}
        </Heading>
      )}

      {logs.map((log) => {
        return <LogComponent logWithDetails={log} />;
      })}
      {logs.length === 0 ? (
        <Center width="100%" height="128px">
          <Text color="gray.500">No logs found</Text>
        </Center>
      ) : (
        <></>
      )}
    </Stack>
  );
};
