import { IDashboard } from "app/types";
import { LogoComponent } from "app/components/logo.component";
import { PlaceholderPage } from "modules/core/pages/placeholder.page";
import {
  FaBriefcase,
  FaDashcube,
  FaHistory,
  FaQuestionCircle,
  FaUserCircle
} from "react-icons/fa";
import { UserListPage } from "modules/user/pages/user-list.page";
import { CreateUserPage } from "modules/user/pages/create-user.page";
import { UserLogPage } from "modules/log/pages/user-log.page";
import { DashboardPage } from "modules/police-case/pages/dashboard.page";
import { CaseLogPage } from "modules/log/pages/case-log.page";
import { SearchCasePage } from "modules/police-case/pages/search-case.page";
import { CaseDetailsPage } from "modules/police-case/pages/case-details.page";
import { PatientDocumentPage } from "modules/police-case/pages/patient-document.page";
import { RecordRequestPage } from "modules/record-request/pages/record-request.page";
import { ConnectRecordRequestPage } from "modules/record-request/pages/connect-record-request.page";
import { RecordRequestLogPage } from "modules/log/pages/record-request-log.page";

export const adminDashboard: IDashboard = {
  dashboardName: "admin",
  logo: <LogoComponent />,
  pages: [
    {
      name: "Dashboard",
      link: "/admin/dashboard",
      content: <DashboardPage />
    },
    {
      name: "User List",
      link: "/admin/user/list",
      content: <UserListPage />
    },
    {
      name: "Add User",
      link: "/admin/user/add",
      content: <CreateUserPage />
    },
    {
      name: "User Details",
      link: "/admin/user/:userId/details",
      content: <PlaceholderPage />
    },
    {
      name: "Case List",
      link: "/admin/case/list",
      content: <PlaceholderPage />
    },
    {
      name: "Search Case",
      link: "/admin/case/search",
      content: <SearchCasePage />
    },
    {
      name: "Upload Case Documents",
      link: "/admin/document/upload",
      content: <PatientDocumentPage />
    },
    {
      name: "Case Details",
      link: "/admin/case/:caseId/details",
      content: <CaseDetailsPage />
    },
    {
      name: "User Logs",
      link: "/admin/user/:userId/activity-log/list",
      content: <UserLogPage />
    },
    {
      name: "Case Logs",
      link: "/admin/case/:caseId/activity-log/list",
      content: <CaseLogPage />
    },
    {
      name: "Record Request Logs",
      link: "/admin/record-request/activity-log/list",
      content: <RecordRequestLogPage />
    },
    {
      name: "Record Request Details",
      link: "/admin/record-request/:id",
      content: <ConnectRecordRequestPage />
    },
    {
      name: "Record Request List",
      link: "/admin/record-request/list",
      content: <RecordRequestPage />
    }
  ],

  sidebar: [
    {
      header: "Dashboard",
      icon: FaDashcube,

      pages: [
        {
          name: "Overview",
          link: "/admin/dashboard",
          content: <DashboardPage />
        }
      ]
    },
    // {
    //   name: "Dashboard",
    //   link: "/admin/dashboard",
    //   content: <DashboardPage />
    // },
    {
      header: "Case Management",
      icon: FaBriefcase,
      pages: [
        // {
        //   name: "Case List",
        //   link: "/admin/case/list",
        //   content: <SearchCasePage />
        // },
        {
          name: "Search Case",
          link: "/admin/case/search",
          content: <SearchCasePage />
        }
        // {
        //   name: "Case Details",
        //   link: "/admin/case/default/details",
        //   content: <CaseDetailsPage />
        // }
      ]
    },
    {
      header: "User Management",
      icon: FaUserCircle,
      pages: [
        {
          name: "User List",
          link: "/admin/user/list",
          content: <PlaceholderPage />
        },
        {
          name: "Add User",
          link: "/admin/user/add",
          content: <PlaceholderPage />
        }
        // {
        //   name: "User Details",
        //   link: "/admin/user/default/details",
        //   content: <PlaceholderPage />
        // }
      ]
    },
    {
      header: "Activity Logs",
      icon: FaHistory,
      pages: [
        {
          name: "User Logs",
          link: "/admin/user/default/activity-log/list",
          content: <UserLogPage />
        },
        {
          name: "Case Logs",
          link: "/admin/case/default/activity-log/list",
          content: <CaseLogPage />
        },
        {
          name: "Record Request Logs",
          link: "/admin/record-request/activity-log/list",
          content: <RecordRequestLogPage />
        }
      ]
    },
    {
      header: "Record Request",
      icon: FaQuestionCircle,
      pages: [
        {
          name: "Record Request List",
          link: "/admin/record-request/list",
          content: <RecordRequestPage />
        }
      ]
    }
  ]
};
