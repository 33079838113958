import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet
} from "@react-pdf/renderer";
import { env } from "app/config";
import dayjs from "dayjs";
import React from "react";
import { GetRecordRequestByIdResponse } from "../api/type";

interface PrintableCaseDocumentPageProps {
  data: GetRecordRequestByIdResponse;
}

Font.register({
  family: "Noto Serif Bengali",
  src: "/fonts/NotoSerifBengali-Regular.ttf"
});

const styles = StyleSheet.create({
  body: {
    padding: "0.75in",
    fontFamily: "Noto Serif Bengali"
  },
  logo: {
    width: "70px",
    objectFit: "contain",
    alignSelf: "center"
  },
  recordImage: {
    objectFit: "contain",
    alignSelf: "center"
  },
  hospitalName: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center"
  },
  hospitalInformation: {
    fontSize: 11,
    fontWeight: "semibold",
    textAlign: "center"
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10
  },
  subTitle: {
    fontSize: 13,
    fontWeight: "semibold",
    marginVertical: 15
  },
  requestTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "left",
    fontSize: 11
  },
  requestInfo: {
    width: "50%",
    border: "1px solid #dfdfdf",
    paddingHorizontal: 5,
    paddingVertical: 1
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "absolute",
    fontSize: 8,
    color: "#666666",
    bottom: "0.50in",
    left: "0.75in",
    right: "0.75in",
    borderTop: "1px solid #000"
  }
});

export const PrintableCaseDocumentPage: React.FC<
  PrintableCaseDocumentPageProps
> = (props: PrintableCaseDocumentPageProps) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View>
          <View>
            <Image style={styles.logo} src={env.hospitalLogo} />
            <Text style={styles.hospitalName}>{env.hospitalName}</Text>
            <Text style={styles.hospitalInformation}>
              Address: {env.hospitalAddress}
            </Text>
            <Text style={styles.hospitalInformation}>
              Contact: {env.hospitalContact}
            </Text>
          </View>

          <Text style={styles.subTitle}>Record Request Document File :</Text>
          <View>
            <View style={styles.requestTable}>
              <Text style={styles.requestInfo}>Request Source :</Text>
              <Text style={styles.requestInfo}>
                {props.data.recordRequestWithCases.recordRequest
                  .requestSource || "_"}
              </Text>
            </View>
            <View style={styles.requestTable}>
              <Text style={styles.requestInfo}>Request Date :</Text>
              <Text style={styles.requestInfo}>
                {dayjs(
                  props.data.recordRequestWithCases.recordRequest.requestedAt
                ).format("DD MMM YYYY, hh:mm A") || "_"}
              </Text>
            </View>
            <View style={styles.requestTable}>
              <Text style={styles.requestInfo}>Deadline :</Text>
              <Text style={styles.requestInfo}>
                {dayjs(
                  props.data.recordRequestWithCases.recordRequest.deadline
                ).format("DD MMM YYYY, hh:mm A") || "_"}
              </Text>
            </View>
            <View style={styles.requestTable}>
              <Text style={styles.requestInfo}>Record Reference :</Text>
              <Text style={styles.requestInfo}>
                {props.data.recordRequestWithCases.recordRequest
                  .requestDocumentText || "_"}
              </Text>
            </View>
          </View>
          <Text style={styles.subTitle}>Document Image :</Text>
          <View>
            {props.data.recordRequestWithCases.recordRequest
              .requestDocumentUrl ? (
              <Image
                style={styles.recordImage}
                src={
                  props.data.recordRequestWithCases.recordRequest
                    .requestDocumentUrl
                }
              />
            ) : (
              <Text style={{ fontSize: "11px", textAlign: "center" }}>
                No image found !!
              </Text>
            )}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View>
            <Text>Powered by Bindulogic Limited</Text>
          </View>
          <View>
            <Text>
              Printed By:{" "}
              {window.localStorage.getItem("locationName") || "Unknown"},{" "}
              {dayjs().format("DD MMM YYYY, hh:mm A")}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
