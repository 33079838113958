import React from "react";
import {
  Button,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { PoliceCaseWithDetails } from "app/api/type";
import { useConnectCaseWithRecordMutation } from "../api";
import { getDashboardName } from "utils/dashboard-name/get-dashboard-name.util";
import { useNavigate } from "react-router-dom";

interface PoliceCaseTableComponentProps {
  requestId: string;
  policeCasesWithDetails: PoliceCaseWithDetails[];
}

export const ConnectPoliceCaseTableComponent: React.FC<
  PoliceCaseTableComponentProps
> = (props: PoliceCaseTableComponentProps) => {
  const [connectCase, connectCaseResult] = useConnectCaseWithRecordMutation();
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Sl.</Th>
            <Th>Creation Date</Th>
            <Th>ID</Th>
            <Th>Patients</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.policeCasesWithDetails.map((policeCaseWithDetail, index) => {
            return (
              <Tr key={index}>
                <Td fontSize={{ base: "xs", md: "sm" }}>{index + 1}</Td>
                <Td fontSize={{ base: "xs", md: "sm" }}>
                  {dayjs(policeCaseWithDetail.policeCase.createdAt).format(
                    "MMM DD, YYYY hh:mm A"
                  )}
                </Td>
                <Td fontSize={{ base: "xs", md: "sm" }}>
                  {policeCaseWithDetail.policeCase.id}
                </Td>
                <Td fontSize={{ base: "xs", md: "sm" }}>
                  <Stack>
                    {policeCaseWithDetail.subjects.map((subject, index) => {
                      return (
                        <Text key={index}>
                          {`${subject.person.name} (${
                            subject.person.contactNumber
                              ? subject.person.contactNumber
                              : "-"
                          })`}
                        </Text>
                      );
                    })}
                  </Stack>
                </Td>
                <Td>
                  <Stack direction="row">
                    <Button
                      colorScheme="blue"
                      onClick={() => {
                        const locationCategory = window.localStorage.getItem(
                          "locationCategory"
                        ) as string;
                        navigate(
                          `/${getDashboardName(locationCategory)}/case/${
                            policeCaseWithDetail.policeCase.id
                          }/details`
                        );
                      }}
                    >
                      Details
                    </Button>
                    <Button
                      size={{ base: "xs", lg: "sm" }}
                      colorScheme="blue"
                      onClick={() => {
                        connectCase({
                          data: {
                            recordRequest: { id: props.requestId },
                            connect: [policeCaseWithDetail.policeCase.id],
                            disconnect: []
                          }
                        });
                      }}
                    >
                      Connect
                    </Button>
                  </Stack>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
